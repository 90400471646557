<template>
	<div class="page_container">
		<TOPNAV handleIndex="1"></TOPNAV>
		<SLIDER :tab1="tab1" :tab2="tab2"></SLIDER>
		<div class="pc_container">
			<div class="flex flex_between flex_items" style="margin-bottom: 32px;">
				<div class="page_title">账号信息</div>
			</div>
			<div>
				<div class="list flex flex_items mb24">
					<div class="list_name c9 f15" style="width:80px"><span class="main_color_red">*</span>公司名称</div>
					<input type="text" class="inp_bor inp f14" v-model="nickname" placeholder="请输入公司名称">
				</div>
				<div class="list flex flex_items mb24">
					<div class="list_name c9 f15" style="width:80px">公司简介</div>
					<input type="text" class="inp_bor inp f14" v-model="detail" placeholder="请输入公司简介">
				</div>
				<div class="list flex flex_items" style="margin-top: 56px;">
					<div class="list_name c9 f15"></div>
					<div class="page_btn main_bg" @click="save">保存</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import TOPNAV from '@/components/topNav.vue'
	import SLIDER from '@/components/slider.vue'
	import {
		path
	} from '@/utils/data.js'
	export default ({
		components: {
			TOPNAV,
			SLIDER
		},
		data() {
			return {
				nickname: '',
				tab1: [{
					name: '客户管理',
					path: path(1)
				}],
				tab2: '',
				total: 20,
				pageSize: 10,
				currentPage: 1,
				id: '',
				sex:'1',
				detail:'',
			}
		},
		created() {
			this.id = this.$route.query.id;
			document.title = this.tab2 = this.id ? '编辑公司' : '新建公司'
			if(this.id){
				this.getData()
			}
		},
		methods: {
			getData() {
				this.api.companyView({id:this.id}).then(res => {
					// this.$message.success('添加成功')
					// this.$router.go(-1)
					this.nickname = res.data.name;
					this.detail = res.data.detail;
				})
			},
			save(){
				if (!this.nickname) {
					return this.$message.error("公司名称不能为空");
				}
				this.api.companySave({name:this.nickname,detail:this.detail,id:this.id}).then(res => {
					this.$message.success('添加成功')
					this.$router.go(-1)
				})
			},
			pageSizeC(e) {
				this.pageSize = e;
				this.currentPage = 1
				this.getData()
			},
			currentChange(e) {
				this.currentPage = e
				this.getData();
			},
		},
	})
</script>
<style src="@/assets/css/pc.css" scoped></style>
<style scoped>
.page_btn{
	width: 95px;
	height: 48px;
	font-size: 18px;
}
</style>