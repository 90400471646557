<template>
  <div></div>
</template>

<script>
export default {
  name: "mine",
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  created() {},
};
</script>
