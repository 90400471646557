
const state = {
  msgNum: 0,
  socketFlag:0,
  isOpenid:0
}

const mutations = {
  SET_NUM: (state, num) => {
    state.msgNum = num
  },
  SET_SOCKETFLAG: (state, num) => {
    state.socketFlag = num
  },
  SET_OPENID:(state, num) =>{
    state.isOpenid = num
  }
}

const actions = {
  setNum({ commit, state }, num) {
    commit('SET_NUM', num)
  },
  setSocketFlag({ commit, state }, num) {
    commit('SET_SOCKETFLAG', num)
  },
  setOpenid({ commit, state }, num) {
    commit('SET_OPENID', num)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
