<template>
	<div class="page_container">
		<TOPNAV></TOPNAV>
		<SLIDER :tab1="tab1" :tab2="tab2"></SLIDER>
		<div class="pc_container">
			<div class="flex flex_between flex_items" style="margin-bottom: 32px;">
				<div class="page_title">账号信息</div>
			</div>
			<div>
				<div class="list flex flex_items mb24">
					<div class="list_name c9 f15"><span class="main_color_red">*</span>账号</div>
					<input type="text" class="inp_bor inp f14" v-model="username" placeholder="请输入账号">
				</div>
				<div class="list flex flex_items mb24">
					<div class="list_name c9 f15">密码</div>
					<input type="password" class="inp_bor inp f14" v-model="password" placeholder="请设置登录密码">
				</div>
				<div class="list flex flex_items mb24">
					<div class="list_name c9 f15">确认密码</div>
					<input type="password" class="inp_bor inp f14" v-model="repassword" placeholder="请确认密码">
				</div>
				<div class="list flex flex_items mb24">
					<div class="list_name c9 f15">姓名</div>
					<input type="text" class="inp_bor inp f14" v-model="realname" placeholder="请输入姓名">
				</div>
				<div class="list flex flex_items mb24">
					<div class="list_name c9 f15">手机号</div>
					<input type="number" class="inp_bor inp f14" v-model="mobile" placeholder="请输入手机号">
				</div>
				<div class="list flex flex_items mb24">
					<div class="list_name c9 f15">邮箱</div>
					<input type="text" class="inp_bor inp f14" v-model="email" placeholder="请输入邮箱">
				</div>
				<div class="list flex flex_items mb24">
					<div class="list_name c9 f15">是否为管理员</div>
					<div class="inp-bor inp  flex flex_items" style="background: #fff;">
						<el-checkbox v-model="is_admin" :true-label="1" :false-label="0" @change="handleChange($event, 'is_admin')"
							style="margin-right: 60px;"></el-checkbox>
					</div>
				</div>
				<div class="list flex flex_items">
					<div class="list_name c9 f15">状态</div>
					<div class="inp-bor inp  flex flex_items" style="background: #fff;">
						<el-radio-group v-model="status" text-color="#4eae92">
							<el-radio :label="1">开启</el-radio>
							<el-radio :label="0">停用</el-radio>
						</el-radio-group>
					</div>
				</div>
				<div class="list flex flex_items" style="margin-top: 56px;">
					<div class="list_name c9 f15"></div>
					<div class="page_btn main_bg" @click="save">保存</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import TOPNAV from '@/components/topNav.vue'
	import SLIDER from '@/components/slider.vue'
	import {
		path
	} from '@/utils/data.js'
	import {
		checkPhone,APPID
	} from '@/utils/tool'
	export default ({
		components: {
			TOPNAV,
			SLIDER
		},
		data() {
			return {
				username: '',
				realname: '',
				mobile: '',
				email: '',
				tab1: [{
					name: '账号管理',
					path: path(0)
				}],
				tab2: '',
				total: 20,
				pageSize: 10,
				currentPage: 1,
				id: '',
				sex: '1',
				password: '',
				is_admin: 0,
				repassword: '',
				cid:'',
        status:1

			}
		},
		created() {
			this.id = this.$route.query.id;
			this.cid = this.$route.query.cid;
			document.title = this.tab2 = this.id ? '编辑账号' : '新增账号'
			if(this.cid && this.id) {
				this.getCompData() 
				return
			};
			if (this.id) this.getData();
			
		},
		methods: {
			getData() {
				this.api.accountView({
					id: this.id
				}).then(res => {
					this.username = res.data.username
					this.mobile = res.data.mobile
					this.is_admin = res.data.is_admin
					this.realname = res.data.realname
					this.email = res.data.email
          this.status = Number(res.data.status)
				})
			},
			getCompData() {
				this.api.customView({
					id: this.id
				}).then(res => {
					this.username = res.data.username
					this.mobile = res.data.mobile
					this.is_admin = res.data.is_admin
					this.realname = res.data.realname
					this.email = res.data.email
          this.status = Number(res.data.status)
				})
			},
			handleChange(e, d) {
				this[d] = e ? 1 : 0
			},
			pageSizeC(e) {
				this.pageSize = e;
				this.currentPage = 1
				this.getData()
			},
			currentChange(e) {
				this.currentPage = e
				this.getData();
			},
			save() {
				if (!this.username) {
					return this.$message.error("账号不能为空");
				}
				if (this.password) {
					if (!this.repassword) return this.$message.error("请确认密码");
					if (this.repassword != this.password) return this.$message.error("密码有误");
				}
				if (this.mobile) {
					if (!checkPhone(this.mobile)) return this.$message.error("手机号有误");
				}
				if(this.cid){
					this.api.customSave({
						username: this.username,
						password: this.password,
						mobile: this.mobile,
						is_admin: this.is_admin,
						realname: this.realname,
						email: this.email,
						company_id:this.cid,
						id:this.id,
            status:this.status,
					}).then(res => {
						this.$message.success(this.id ? '修改成功' : '添加成功')
						this.$router.go(-1)
					})
					return
				}
				this.api.accountSave({
					username: this.username,
					password: this.password,
					mobile: this.mobile,
					is_admin: this.is_admin,
					realname: this.realname,
					email: this.email,
					id:this.id,
          status:this.status,
				}).then(res => {
					this.$message.success(this.id ? '修改成功' : '添加成功')
					this.$router.go(-1)
				})
			}
		},
	})
</script>
<style src="@/assets/css/pc.css" scoped></style>
<style scoped>
	.page_btn {
		width: 95px;
		height: 48px;
		font-size: 18px;
	}

	.list_name {
		width: 100px;
	}
</style>