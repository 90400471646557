<template>
  <div class="">
    <div class="flex flex_between flex_items" style="margin-bottom: 32px;">
      <div class="page_title">{{phone ? '更换绑定手机号' : '绑定手机号'}}</div>
    </div>
    <div>
      <template v-if="!phone">
        <div class="list flex flex_items mb24">
          <div class="list_name c9 f15" style="width:80px">
            手机号
          </div>
          <input
            type="text"
            class="inp_bor inp f14"
            v-model="mobile"
            placeholder="请输入手机号"
          />
        </div>
        <div class="list flex flex_items mb24">
          <div class="list_name c9 f15" style="width:80px">
            验证码
          </div>
          <div class="flex flex_items">
            <input
              type="text"
              class="inp_bor inp f14 code-inp"
              v-model="code"
              placeholder="请输入验证码"
            />
            <div class="page_btn page_btn1 main_bg" @click="getCode" v-show="count == 60">获取验证码</div>
            <div class="page_btn page_btn1 main_bg" @click="getCode" v-show="count < 60">{{ count }}秒重试</div>
          </div>
        </div>
        <div class="list flex flex_items" style="margin-top: 56px;">
          <div class="list_name c9 f15" style="width:80px"></div>
          <div class="page_btn main_bg" @click="save">保存</div>
        </div>
      </template>
      <template v-else>
        <div class="list flex flex_items mb24">
          <div class="list_name c9 f15" style="width:80px">
            <!-- <span class="main_color_red">*</span> -->
            手机号
          </div>
          <div class="flex flex_items">
            <input
              type="text"
              class="inp_bor dis-inp inp f14 code-inp"
              v-model="phone"
              disabled
              placeholder="请输入验证码"
            />
            <div class="page_btn page_btn1 main_bg" @click="phone = ''">更换</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import TOPNAV from "@/components/topNav.vue";
import SLIDER from "@/components/slider.vue";
import { path } from "@/utils/data.js";
import { checkPhone } from "@/utils/tool.js";

export default {
  components: {
    TOPNAV,
    SLIDER,
  },
  data() {
    return {
      nickname: "",
      tab1: [
        {
          name: "站点管理",
          path: path(2),
        },
      ],
      tab2: "",
      total: 20,
      pageSize: 10,
      currentPage: 1,
      count:60,
      id: "",
      sex: "1",
      mobile:'',
			code:'',
      phone:'',//原有的手机号
    };
  },
  created() {
    this.id = this.$route.query.id;
		this.phone = this.$storage.getItem('user').mobile;
    document.title = this.tab2 = this.id ? "编辑站点" : "新建站点";
		let user = this.$storage.getItem('user');
  },
  methods: {
    getCode() {
      if (!checkPhone(this.mobile)) {
        return this.$message({
          type:'error',
          message:'手机号有误',
          duration:1500
        });
      }
      
      this.api.sms({ mobile: this.mobile}).then((res) => {
        this.$message.success("发送成功");
        if(res.data.code){
          this.code = res.data.code;
        }
        this.count -= 1;
        let a = setInterval(() => {
          this.count -= 1;
          if (this.count == 1) {
            this.count = 60;
            clearInterval(a);
          }
        }, 1000);
      });
    },
    save() {
			if (!checkPhone(this.mobile)) {
			  return this.$message({
			    type:'error',
			    message:'手机号有误',
			    duration:1500
			  });
			}
			if (!this.code) {
				return this.$message.error("请输入验证码");
			}
			this.api.mobile({mobile:this.mobile,code:this.code}).then(res => {
				let user = this.$storage.getItem('user');
				user.mobile = this.mobile;
				this.$storage.setItem({
					name:'user',
					value:user,
				})
				this.$message.success('操作成功')
				this.phone = this.$storage.getItem('user').mobile;
			})
		},
    pageSizeC(e) {
      this.pageSize = e;
      this.currentPage = 1;
      this.getData();
    },
    currentChange(e) {
      this.currentPage = e;
      this.getData();
    },
  },
};
</script>
<style src="@/assets/css/pc.css" scoped></style>
<style scoped>
.page_btn {
  width: 190px;
  height: 48px;
  font-size: 18px;
}
.page_btn1.page_btn{
  width: 116px;
  height: 42px;
  font-size: 15px;
}
.img-box {
  margin-right: 28px;
}
.img {
  width: 56px;
  height: 56px;
  display: block;
}
.icon {
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  bottom: 0;
  z-index: 1;
  right: -12px;
}
.code-inp.inp{
  width:260px;
  margin-right: 24px;
}
.dis-inp.code-inp.inp{
  background: #F7FAFC;
  width: 400px;
  color: #999;
}
</style>
