<template>
  <div></div>
</template>
<script>
import { getQueryString } from "@/utils/tool";

export default {
  data() {
    return {
      code: "",
    };
  },
  created() {
    this.code = getQueryString("code");
    console.log("code", this.code);
    if (getQueryString("code")) {
      //微信登录
      this.getWeixin();
    } else {
      this.$router.go(-1);
      sessionStorage.setItem("unionid", "");
      sessionStorage.setItem("openid", "");
    }
  },
  methods: {
    getWeixin() {
      //微信登录
      this.api
        .wechatCode({ islogin: false, code: getQueryString("code") })
        .then(
          (res) => {
            let obj = {
              name: "token",
              value: res.data.token,
            };
            this.$storage.setItem(obj);
            this.$storage.setItem({
              name: "user",
              value: res.data,
            });
            this.$router.replace("/");
          },
          (res) => {
            console.log(res,'----------------------');
            sessionStorage.setItem("unionid", res.data.data.unionid);
            sessionStorage.setItem("openid", res.data.data.openid);
            this.$router.go(-1);
          }
        );
    },
  },
};
</script>
