<template>
	<div>
		<TOPNAV :handleIndex="handleIndex"></TOPNAV>
		<div class="page_container">
			<div class="pc_container">
				<div class="flex flex_between pt24 flex_items" style="margin-bottom: 14px;">
					<div class="page_title">账号列表</div>
					<div class="page_btn main_bg" @click="$router.push({path:'/addAccount',query:{cid:cid}})">新建账号</div>
				</div>
				<div>
					<el-table :data="list" style="width: 100%"
						:header-cell-style="{background:'#F3F3F3',color:'#1F3349',fontSize:'15px',fontWeight:'normal'}"
						:cell-style="{fontSize:'15px',color:'#666666'}">
						<el-table-column prop="realname" label="姓名" align="center">
						</el-table-column>
						<el-table-column align="center" prop="username" label="账号名">
						</el-table-column>
						<el-table-column align="center" prop="mobile" label="手机号">
						</el-table-column>
						<el-table-column align="center" prop="email" label="邮箱">
						</el-table-column>
						<el-table-column align="center" prop="status" label="状态">
							<template slot-scope="scope">
								<div class="" :class="scope.row.status == 0 ? 'main_color_red' : 'c6'">
									{{scope.row.status == 0 ? '停用' : '启用'}}
								</div>
							</template>
						</el-table-column>
						<el-table-column fixed="right" align="center" label="操作" width="210">
							<template slot-scope="scope">
								<div class="flex flex_items flex_center">
									<div @click="$router.push({path:'/addAccount',query:{id:scope.row.id,cid:cid}})" class="main_bg  s_btn">
										编辑
									</div>
									<div class="main_bg_red ml10 s_btn" @click="del(scope.row.id)">删除</div>
									<div @click="$router.push({path:'/manageAccount',query:{id:scope.row.id,cid:cid}})" 
										style="width: 81px;" class="ml10 main_bg_ba s_btn" v-if="scope.row.role != vRole">
										账号授权
									</div>
								</div>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div class="flex flex_end" style="margin-top: 32px;">
					<el-pagination background layout="prev, pager, next,sizes,jumper" :total="total" @current-change="currentChange"
						:current-page="currentPage" :page-size="pageSize" @size-change="pageSizeC">
					</el-pagination>
				</div>
			</div>
		</div>
		<el-dialog title="删除" :visible.sync="pop" width="330px" top="35vh" :close-on-click-modal="false">
			<delPop @handlePop="handlePop" title="是否确定删除该账号？"></delPop>
		</el-dialog>
	</div>
</template>
<script>
	import TOPNAV from '@/components/topNav.vue'
	import delPop from '@/components/delPop.vue'
	import {pageSize,vRole} from '@/utils/tool'
	export default ({
		components: {
			TOPNAV,delPop
		},
		data() {
			return {
				list: [],
				total: 20,
				pageSize: pageSize,
				currentPage: 1,
				pop:false,
				txt:'',
				delId:'',
				cid:'',//公司id
				handleIndex:'',
				vRole:vRole
			}
		},
		watch: {
			delId(newValue, oldValue) {
				if (!newValue) this.delId = ''
			}
		},
		created(){
			this.cid = this.$route.query.cid;
			this.handleIndex = this.cid ? 1 : 0;
			if(this.cid){
				this.getCompData()
			}else{
				this.getData()
			}
		},
		methods: {
			handlePop(t) {
				if(t == 0){
					this.pop = false
				}else{
					if(this.cid){
						this.api.customDelete({id:this.delId}).then(res => {
							this.$message.success('删除成功')
							this.getCompData()
							this.pop = false
						},() => {
							this.pop = false
						})
					}else{
						this.api.accountDelete({id:this.delId}).then(res => {
							this.$message.success('删除成功')
							this.getData()
							this.pop = false
						},() => {
							this.pop = false
						})
					}
					
				}
			},
			del(id) {
				this.delId = id;
				this.pop = true;
			},
			downFormSure(){},
			getCompData() {
				this.api.companyView({id:this.cid}).then(res => {
          if(res.code == 0){
            this.list = res.data.users
          }
				})
			},
			getData() {
				this.api.accountList({page:this.currentPage,per_page:this.pageSize}).then(res => {
					if(res.code == 0){
            this.list = res.data.data;
					  this.total = res.data.total;
          }
				})
			},
			pageSizeC(e) {
				this.pageSize = e;
				this.currentPage = 1
				this.getData()
			},
			currentChange(e) {
				this.currentPage = e
				this.getData();
			},
		},
	})
</script>
<style src="@/assets/css/pc.css" scoped></style>
<style scoped>
.p-top-title{
    position: absolute;
    top: -12px;
    left: 24px;
  }
  .p-btn{
		width: 100px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		font-size: 16px;
		border-radius: 2px;
	}
  .p-name{
    width: 50px;
  }
  .p-inp{
    border: 1px solid #eee;
    height: 36px;
    padding: 0 10px;
  }
</style>