<template>
  <div id="appLoading">
    <div class="loading"></div>
  </div>
</template>
<script>
import { getQueryString} from "@/utils/tool";
export default {
  created() {
    
  },
  methods: {},
};
</script>
<style scoped></style>
