<template>
	<div id="pages" :class="[bg == 1 ? 'bg1' : bg == 2 ? 'bg2' : 'bgfff',path == 'gift' ? 'overHidden' : '']">
		<!-- <div>首页</div> -->
		<!-- <keep-alive> -->
		<router-view />
		<!-- </keep-alive> -->
		<tabbar id="pageTab" v-model="active" active-color="#4FD9D5" inactive-color="#888888" @change="onChange" style="height:60px">
			<tabbar-item name="home">
				<span>首页</span>
				<template #icon="props">
					<div class="pos table-icon-img-box">
						<img class="table-icon-img" :class="props.active ? 'pop-img-check' : ''" :src="
                props.active
                  ? require('../../assets/member/home_onpress.png')
                  : require('../../assets/member/home.png')
              " />
					</div>
				</template>
			</tabbar-item>
			<!-- <tabbar-item name="msg" :badge="msgNum == 0 ? '' : msgNum" icon="setting-o">
				<span>消息</span>
				<template #icon="props">
					<div class="pos table-icon-img-box">
						<img class="table-icon-img" :class="props.active ? 'pop-img-check' : ''" :src="
                props.active
                  ? require('../../assets/member/msg_onpress.png')
                  : require('../../assets/member/msg.png')
              " />
					</div>
				</template>
			</tabbar-item> -->
			<tabbar-item name="gift" icon="setting-o">
				<span>礼品</span>
				<template #icon="props">
					<div class="pos table-icon-img-box">
						<img class="table-icon-img" :class="props.active ? 'pop-img-check' : ''" :src="
			          props.active
			            ? require('../../assets/member/gift_o.png')
			            : require('../../assets/member/gift.png')
			        " />
					</div>
				</template>
			</tabbar-item>
			<tabbar-item :name="mine" icon="setting-o">
				<span>我的</span>
				<template #icon="props">
					<div class="pos table-icon-img-box">
						<img class="table-icon-img" :class="props.active ? 'pop-img-check' : ''" :src="
                props.active
                  ? require('../../assets/member/mine_onpress.png')
                  : require('../../assets/member/mine.png')
              " />
					</div>
				</template>
			</tabbar-item>
		</tabbar>
		<div class="lien"></div>
	</div>
</template>

<script>
	import {
		Tabbar,
		TabbarItem
	} from "vant";
	export default {
		name: "home",
		props: {},
		components: {
			Tabbar,
			TabbarItem
		},
		data() {
			return {
				active: "home",
				mine: "mine",
				msgNum: this.$store.getters.msgNum,
				bg: 1,
				path:''
			};
		},
		computed: {},
		watch: {
			$route: "getPath",
			"$store.state.app.msgNum": {
				handler: function(newVal) {
					this.msgNum = newVal;
				},
				deep: true,
			},
		},
		mounted() {},
		created() {
			this.active = this.$route.name;
			console.log(this.$route.path);
			let path = this.$route.path;
			if(path == '/msg'){
				this.bg = 2
			}else if(path == '/home' || path == '/mine'){
				this.bg = 1
			}
			if(path == '/gift'){
				this.path = 'gift'
			}else{
				this.path = ''
			}
			// const user =
			//   localStorage.getItem("user") &&
			//   JSON.parse(localStorage.getItem("user")).is_teacher == 1
			//     ? true
			//     : false;
			// if (user) {
			//   this.mine = "tmine";
			// }
		},
		methods: {
			getPath() {
				// console.log(this.$route.path);
				let path = this.$route.path;
				if (path == "/tmine" || path == "/mine") {
					this.active = path.substring(1);
				}
			},
			onChange(e) {
				// console.log(e)
				this.path = e
				switch (e) {
					case "home":
						this.bg = 1
						this.$router.replace({
							path: "/home"
						});
						break;
					case "zx":
						this.$router.replace({
							path: "/zx"
						});
						break;
					case "sq":
						this.$router.replace({
							path: "/sq"
						});
						break;
					// case "msg":
					// 	this.bg = 2
					// 	this.$router.replace({
					// 		path: "/msg"
					// 	});
					// 	break;
					case "gift":
						this.bg = 2
						this.$router.replace({
							path: "/gift"
						});
						break;
					case "mine":
						this.bg = 1
						this.$router.replace({
							path: "/mine"
						});
						break;
						// case "tmine":
						//   this.$router.replace({ path: "/tmine" });
						//   break;
				}
			},
		},
	};
</script>
<style scoped>
	#pages {
		/* background:#fff; */
		min-height: 100vh;
	}
	#pages.overHidden{
		height: 100vh;
	}

	.bg1 {
		background: linear-gradient(180deg, rgba(243, 251, 247, 0.5) 0%, #FFFFFF 100%);
	}

	.bg2 {
		background: #F9F9F9;
	}

	.lien {
		height: 100px;
	}

	.table-icon-img-box {
		width: 0.45rem;
		height: 0.45rem;
	}

	.table-icon-img {
		width: 0.45rem;
		height: 0.45rem;
	}

	.table-icon-img.pop-img-check {
		width: 0.81rem;
		height: 0.78rem;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: 0;
		z-index: 2;
	}
</style>
<style type="text/css">
	.van-tabbar--fixed{
		z-index: 999;
	}
</style>