<template>
	<div>
		<TOPNAV handleIndex="2"></TOPNAV>
		<SLIDER :tab1="tab1" :tab2="tab2"></SLIDER>
		<div class="pc_container">
			<div class="flex flex_between flex_items" style="margin-bottom: 32px;">
				<div class="page_title">账号信息</div>
			</div>
			<div>
				<div class="list flex flex_items mb24">
					<div class="list_name c9 f15" style="width:80px"><span class="main_color_red">*</span>站点名称</div>
					<input type="text" class="inp_bor inp f14" v-model="nickname" placeholder="请输入站点名称">
				</div>
        <div class="list flex flex_items mb24">
					<div class="list_name c9 f15" style="width:80px"><span class="main_color_red">*</span>站点图标</div>
					<div class="flex">
            <div class="pos img-box">
              <img src="@/assets/pc/icon1.png" class="img">
              <img src="@/assets/pc/checked.png" class="icon">

            </div>
          </div>
				</div>
        <div class="list flex flex_items mb24">
					<div class="list_name c9 f15" style="width:80px">备注</div>
					<input type="text" class="inp_bor inp f14" v-model="nickname" placeholder="请输入站点备注">
				</div>
				<div class="list flex flex_items" style="margin-top: 56px;">
					<div class="list_name c9 f15" style="width:80px"></div>
					<div class="page_btn main_bg">保存</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import TOPNAV from '@/components/topNav.vue'
	import SLIDER from '@/components/slider.vue'
	import {
		path
	} from '@/utils/data.js'
	export default ({
		components: {
			TOPNAV,
			SLIDER
		},
		data() {
			return {
				nickname: '',
				tab1: [{
					name: '站点管理',
					path: path(2)
				}],
				tab2: '',
				total: 20,
				pageSize: 10,
				currentPage: 1,
				id: '',
				sex:'1'
			}
		},
		created() {
			this.id = this.$route.query.id;
			document.title = this.tab2 = this.id ? '编辑站点' : '新建站点'
		},
		methods: {
			del() {

			},
			getData() {

			},
			pageSizeC(e) {
				this.pageSize = e;
				this.currentPage = 1
				this.getData()
			},
			currentChange(e) {
				this.currentPage = e
				this.getData();
			},
		},
	})
</script>
<style src="@/assets/css/pc.css" scoped></style>
<style scoped>
.page_btn{
	width: 95px;
	height: 48px;
	font-size: 18px;
}
.img-box{
  margin-right: 28px;
}
.img{
  width: 56px;
  height: 56px;
  display: block;
}
.icon{
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  bottom: 0;
  z-index: 1;
  right: -12px;
}
</style>