<template>
  <div class="container">
    <div class="font c6 f30 txt_center">
      {{ type == 1 ? "卓越客户端模块选择入口" : "卓越产品体验快捷入口" }}
    </div>
    <div class="flex flex_warp plr32 flex_between">
      <div
        class="item bgfff flex flex_between"
        v-for="(item, index) in list"
        :key="index"
				@click="gotoPage(item[2])"
      >
        <div>
          <div class="name c2 bold f32 mb16">{{item[0]}}</div>
          <div class="name1 c8 f20 clamp1">{{item[1]}}</div>
        </div>
        <img :src="item[3]" class="img" />
      </div>
    </div>
  </div>
</template>

<script>
import { formatRichText } from "@/utils/tool";
export default {
  data() {
    return {
      id: 1,
      listH: 0,
      msg: {},
      user: "",
      type: 2,
      list: [],
    };
  },
  methods: {
    getData() {
      this.api
        .sites()
        .then((res) => {
          this.list = res.data;
        });
    },
    gotoPage(t) {
    	this.token = this.$storage.getItem('token')
    	if(!this.token){
    		goLoginPage()
    		return
    	}
    	t = t.replace(/{token}/g, this.token);
    	window.open(t, '_blank');
    },
  },
  created() {
    this.user = localStorage.getItem("user");
    // this.id = this.$route.query.id;
    this.getData()
  },
};
</script>
<style src="@/assets/css/mobile.css" scoped></style>
<style scoped="scoped">
.container {
  min-height: 100vh;
  background-color: #f7fafc;
}
.font {
  padding: 0.32rem 0;
}
.item {
  border: 1px solid #eeeeee;
  padding: 0.25rem;
  width: 3.31rem;
  box-sizing: border-box;
  margin-bottom: 0.24rem;
}
.img {
  width: 0.72rem;
  height: 0.72rem;
}
</style>
