<template>
  <div class="">
    <div class="flex flex_between flex_items" style="margin-bottom: 32px;">
      <div class="page_title">修改密码</div>
    </div>
    <div>
			<div class="list flex flex_items mb24">
			  <div class="list_name c9 f15" style="width:90px">
			    <span class="main_color_red">*</span>
			    原密码
			  </div>
			  <input
			    type="password"
			    class="inp_bor inp f14"
			    v-model="old_password"
			    placeholder="请输入原密码"
			  />
			</div>
      <div class="list flex flex_items mb24">
        <div class="list_name c9 f15" style="width:90px">
          <span class="main_color_red">*</span>
          新密码
        </div>
        <input
          type="password"
          class="inp_bor inp f14"
          v-model="new_password"
          placeholder="请输入新密码"
        />
      </div>
      <div class="list flex flex_items mb24">
        <div class="list_name c9 f15" style="width:90px">
          <span class="main_color_red">*</span>
          确认新密码
        </div>
        <input
          type="password"
          class="inp_bor inp f14"
          v-model="rnew_password"
          placeholder="请确认新密码"
        />
      </div>
      <div class="list flex flex_items" style="margin-top: 56px;">
        <div class="list_name c9 f15" style="width:90px"></div>
        <div class="page_btn main_bg" @click="save">保存</div>
      </div>
    </div>
  </div>
</template>
<script>
import TOPNAV from "@/components/topNav.vue";
import SLIDER from "@/components/slider.vue";
import { path } from "@/utils/data.js";
export default {
  components: {
    TOPNAV,
    SLIDER,
  },
  data() {
    return {
      new_password: "",
			rnew_password:'',
			old_password:'',
      id: "",
      sex: "1",
    };
  },
  created() {
    this.id = this.$route.query.id;
    document.title = this.tab2 = this.id ? "编辑站点" : "新建站点";
  },
  methods: {
    save() {
			if (!this.old_password) {
				return this.$message.error("请输入原密码");
			}
			if (!this.new_password) {
				return this.$message.error("请输入新密码");
			}
			if (!this.rnew_password) {
				return this.$message.error("请确认新密码");
			}
			if (this.rnew_password != this.new_password) return this.$message.error("新密码有误");
			this.api.password({
				old_password: this.old_password,
				new_password: this.new_password,
			}).then(res => {
				this.$message.success('修改成功')
			})
		},
  },
};
</script>
<style src="@/assets/css/pc.css" scoped></style>
<style scoped>
.page_btn {
  width: 190px;
  height: 48px;
  font-size: 18px;
}
.img-box {
  margin-right: 28px;
}
.img {
  width: 56px;
  height: 56px;
  display: block;
}
.icon {
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  bottom: 0;
  z-index: 1;
  right: -12px;
}
</style>
