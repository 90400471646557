<template>
	<div class="container">
		<div class="pc_container flex flex_items">
			<div class="bg-box">
				<img class="logo" src="@/assets/pc/logo.png" alt="" />
			</div>
			<div class="content-page flex flex_center" style="height:669px">
				<div class="content-box">
					<div class="font2 c3 bold txt_center">卓越客户登录</div>
					<div class="ser-box bgfff">
						<div class="flex ser flex_center" v-if="handle != 3">
							<div class="pointer" :class="handle == 2 ? 'main_color bold' : 'c9'" @click="handle = 2">账号登录</div>
							<div class="pointer" :class="handle == 1 ? 'main_color bold' : 'c9'" @click="handle = 1">手机号登陆</div>
						</div>
						<div class="flex flex_center flex_column flex_items" v-if="handle == 3" style="margin-bottom:50px">
							<div id="login_container" class="ewm flex flex_center" v-if="handle == 3"></div>
							<div>使用微信扫一扫登录</div>
							<div>“卓越研究通行证”</div>
						</div>
						<div>
							<!-- 账号登录 -->
							<template v-if="handle == 2">
								<div class="input-box">
									<div class="input-box-left"><img src="@/assets/pc/i3.png" /></div>
									<div class="input-box-right">
										<input type="text" v-model="username" placeholder="请输入账号" maxlength="11" />
									</div>
								</div>
								<div class="input-box" style="margin-bottom:25px">
									<div class="input-box-left"><img src="@/assets/pc/i4.png" /></div>
									<div class="input-box-right">
										<input type="password" placeholder="请输入密码" v-model="password" />
									</div>
								</div>
							</template>
							<!-- 手机号登陆 -->
							<template v-if="handle == 1">
								<div class="input-box">
									<div class="input-box-left"><img src="@/assets/pc/i1.png" /></div>
									<div class="input-box-right">
										<input type="text" v-model="mobile" placeholder="请输入手机号码"  maxlength="11" />
									</div>
								</div>
								<div class="input-box">
									<div class="input-box-left"><img src="@/assets/pc/i2.png" /></div>
									<div class="input-box-right">
										<input type="text" placeholder="请输入验证码" v-model="code" />
									</div>
									<div class="get-code main_color pointer" @click="get_code" v-show="count == 60">
										获取验证码
									</div>
									<div class="get-code count" v-show="count < 60">{{ count }}秒重试</div>
								</div>
							</template>
							<div class="c6 flex flex_items pointer" style="font-size:16px" v-if="handle == 2" @click="remb = !remb">
                <img src="@/assets/pc/check1.png" class="check" v-if="remb">
                <img src="@/assets/pc/check.png" class="check" v-else>
                记住我
              </div>
              <div style="margin-bottom:48px">
                <div class="login-btn pointer" @click="login" v-if="handle != 3">登录</div>
                <div style="color:red" class="txt_center mt10" v-if="!showWxLogin">该微信未绑定账号，请继续登录以绑定微信</div>
              </div>
							<template v-if="showWxLogin">
                <div class="flex flex_items flex_center mb32">
                  <div class="cc linner"></div>
                  <div class="c8" style="padding:0 8px">{{handle != 3 ? '微信登录方式' : '其他方式登录'}}</div>
                  <div class="cc linner"></div>
                </div>
                <template v-if="handle != 3">
                  <div class="flex flex_center mb10">
                    <img src="@/assets/pc/wx.png" class="wx pointer" @click="handle = 3">
                  </div>
                  <div class="txt_center">微信</div>
                </template>
                <template v-else>
                  <div class="flex flex_center c6 f14">
                    <div class="flex flex_items flex_center flex_column pointer" style="margin-right:62px"
                      @click="handle = 2">
                      <img src="@/assets/pc/icon10.png" class="s-icon">
                      <div>账号登录</div>
                    </div>
                    <div class="flex flex_items flex_center flex_column pointer" @click="handle = 1">
                      <img src="@/assets/pc/icon11.png" class="s-icon">
                      <div>手机号登录</div>
                    </div>
                  </div>
                </template>
              </template>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Checkbox
	} from "vant";
	import {
		HERF,ewmStyleBase64
	} from "@/utils/tool";
  import { getItem } from '@/utils/storage'
	export default {
		name: "",
		props: {},
		components: {
			Checkbox,
		},
		data() {
			return {
				mobile: "",
        code: "",
        count: 60,
        HERF: HERF,
        check: false,
        handle:2, //1-手机号登陆 2-账号登录 3-微信登录
        username:'',
        password:'',
        remb:true,//记住我
        appid:'',
        showWxLogin:true,
			};
		},
		watch: {
      handle(e){
        if(e == 3){
          this.get_wx_qrcode();//获取微信登录二维码
        }else{
          if(this.$JQ('iframe')){
            this.$JQ('iframe').remove()
          }
        }
      }
    },
		mounted() {},
		created() {
      if(getItem("token")){
        this.api.info().then(res => {
          this.$storage.setItem({
            name:'user',
            value:res.data,
          })
          this.$router.replace('/');
        },() => {
          this.wechatConfig()
        })
      }else{
        if(sessionStorage.getItem('unionid') && sessionStorage.getItem('openid')){
          this.showWxLogin = false;
        }else{
          this.showWxLogin = true;
          this.wechatConfig()
        }
      }
    },
		methods: {
      get_wx_qrcode(){
        this.$nextTick(() => {
            var obj = new WxLogin({
            self_redirect: false,  //值为true时，直接在二维码的弹窗中进行页面跳转及其余操作,
            id: "login_container",       //需要承载二维码的容器id  
            appid: this.appid,
            scope: "snsapi_login",     //网页授权，目前网页只有这一种方式，静默授权
            redirect_uri: encodeURIComponent(this.HERF+'h5/#/loginSuccess'),     //回调域名(地址前部分是项目域名，后部分loginSuccess是微信需要跳转的路径（vue中的路由名称）)
            state: Math.random(),
            style: "white",
            href: ewmStyleBase64
          });
        })
      },
      wechatConfig(){
        this.api.wechatConfig({islogin:false}).then(res => {
          this.appid = res.data.appid
        })
      },
      changeType(type){
        this.handle = type
      },
			getQueryString(str, variable) {
				let herf = str;
				let num = herf.indexOf("?") + 1;
				let query = herf.substring(num);
				let vars = query.split("&");
				for (let i = 0; i < vars.length; i++) {
					let pair = vars[i].split("=");
					if (pair[0] == variable) {
						return pair[1];
					}
				}
				return false;
			},
			login() {
				//1-手机号登陆 2-账号登录 3-微信登录
				if (this.handle == 1) {
					this.phoneLogin()
				} else {
					this.usernameLogin()
				}
			},
			phoneLogin() {
				if (!this.mobile) {
					return this.$message.error("手机号不能为空");
				}
				if (!this.code) {
					return this.$message.error("验证码不能为空");
				}
				this.api.login({
						mobile: this.mobile,
						code: this.code,
						type: 'mobile',
            unionid:sessionStorage.getItem('unionid'),
            openid:sessionStorage.getItem('openid'),
					})
					.then((res) => {
            sessionStorage.removeItem('unionid');
            sessionStorage.removeItem('openid');
						let obj = {
							name: 'token',
							value: res.data.token,
						}
						this.$storage.setItem(obj)
						this.$storage.setItem({
							name: 'user',
							value: res.data,
						})
						this.$router.replace('/');
					}, (res) => {
						console.log(res);
						// if(res.data.code == )
					});
			},
			usernameLogin() {
				if (!this.username) {
					return this.$message.error("账号不能为空");
				}
				if (!this.password) {
					return this.$message.error("密码不能为空");
				}
				this.api.login({
						username: this.username,
						password: this.password,
						type: 'username',
            unionid:sessionStorage.getItem('unionid'),
            openid:sessionStorage.getItem('openid'),
					})
					.then((res) => {
            sessionStorage.removeItem('unionid');
            sessionStorage.removeItem('openid');
						let obj = {
							name: 'token',
							value: res.data.token,
						}
						if (!this.remb) {
							obj.expires = 24 * 60 * 60 * 1000
							obj.startTime = new Date().getTime()
						}
						this.$storage.setItem(obj)
						this.$storage.setItem({
							name: 'user',
							value: res.data,
						})
						this.$router.replace('/');
					}, (res) => {
						console.log(res);
						// if(res.data.code == )
					});
			},

			// 获取验证码
			get_code() {
				if (!this.mobile) {
					return this.Toast("手机号不能为空");
				}
				this.api.sms({
						mobile: this.mobile,
						type: "login",
					})
					.then(() => {
						this.Toast("发送成功");
						this.count -= 1;
						let a = setInterval(() => {
							this.count -= 1;
							if (this.count == 1) {
								this.count = 60;
								clearInterval(a);
							}
						}, 1000);
					});
			},
			// wxsignin(type = 0) {
			//   if (!this.check) {
			//     this.Toast("请勾选用户协议");
			//     return;
			//   }
			//   localStorage.setItem('wxlogin',1)
			//   const appid = APPID;
			//   const url = location.origin + location.pathname + location.hash;
			//   const redirect_uri = encodeURIComponent(url);
			//   // if (type == 1) {

			//   location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=${1}#wechat_redirect`;
			//   // }
			// },
			oninputmobile() {
				if (!/^1[34578]\d{9}$/.test(this.mobile)) {
					this.Toast("手机号码有误，请重填");
				}
			},
			getWxUserInfo(code) {
				let userCode = sessionStorage.getItem("userCode");
				this.code = code;
				this.api
					.login({
						code,
					})
					.then((res) => {
						if (res.code == 200) {
							localStorage.setItem("token", res.data.api_token);
							localStorage.setItem("openid", res.data.openid);
							this.$store.dispatch("app/setOpenid", 1);
							if (localStorage.getItem("fromKey") == 1) {
								//从论坛跳过来登录 返回论坛
								localStorage.removeItem("fromKey");
								let u = localStorage.getItem('sReturnUrl') ? localStorage.getItem('sReturnUrl') : '';
								u = u + '*oid-' + res.data.openid

							} else if (localStorage.getItem("fromKey") == 2) { //留置
								this.gotoLien()
							} else {
								this.$router.replace("home");
							}
						} else if (res.code == 201) {
							localStorage.setItem('wxlogin', 2)
							const appid = APPID;
							const url = location.origin + location.pathname + location.hash;
							const redirect_uri = encodeURIComponent(url);
							location.href =
								`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect`;
						}
					});
			},
			gotoLien() {
				this.api.user().then((res) => { //获取用户手机号
					let obj = {};
					if (JSON.parse(localStorage.getItem("user"))) {
						obj = {
							...JSON.parse(localStorage.getItem("user")),
							...res.data,
						};
					} else {
						obj = res.data;
					}
					localStorage.setItem("user", JSON.stringify(obj));
					localStorage.removeItem("fromKey");
				});
			},
		},
	};
</script>
<style src="@/assets/css/pc.css" scoped></style>
<style lang="scss" scoped>
	.container {
		min-width: 100%;
		height: 100vh;
		background: #E4F3EF;
		overflow-x: auto;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: 1200px;
	}

	.content {
		height: 700px;
		width: 1670px;
		min-width: 1670px;
	}

	.content-box {
		padding-top: 74px;
	}

	.bg-box {
		flex: 1;
		height: 669px;
		background: url('../../../assets/pc/bg1.png') center center no-repeat;
		background-size: cover;
		box-sizing: border-box;
		padding: 37px 0 0 47px;
	}

	.content-page {
		background: #fff;
		width: 450px;
		height: 100%;
		box-sizing: border-box;
	}

	.font1 {
		font-size: 30px;
		margin-bottom: 18px;
	}

	.font2 {
		font-size: 36px;
		margin-bottom: 32px;
	}

	.logo {
		width: 278px;
		overflow: hidden;
		// border-radius: 50%;
		// background-color: #4fd9d5;
	}

	.ser-box {
		border-radius: 5px;
		padding: 0 40px;
		box-sizing: border-box;
		padding-bottom: 32px;
	}

	.ser {
		margin-bottom: 32px;
	}

	.check {
		width: 16px;
		height: 16px;
		margin-right: 8px;
	}

	.ser>div {
		width: 70px;
		box-sizing: border-box;
		text-align: center;
		position: relative;
		height: 24px;
		font-size: 16px;
	}

	.ser>div:last-child {
		width: 95px;
		margin-left: 40px;
	}

	.ser>div.main_color::after {
		position: absolute;
		width: 71px;
		height: 4px;
		border-radius: 2px;
		content: " ";
		bottom: -8px;
		left: 50%;
		transform: translateX(-50%);
		background: #4EAE92;
	}

	.input-box {
		margin-bottom: 32px;
		height: 48px;
		background: #ECF6F4;
		border-radius: 24px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		overflow: hidden;

		.input-box-left {
			padding: 0 11px 0 16px;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 15px;
				height: 17px;
			}
		}

		.input-box-right {
			flex: 1;
			width: 100%;
			height: 100%;

			input {
				width: 100%;
				height: 100%;
				border: none;
				background-color: transparent;
				font-size: 16px;
			}
		}

		.get-code {
			width: 95px;
			font-size: 16px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}
	}

	.login-btn {
		height: 48px;
		width: 330px;
		background: #4EAE92;
		border-radius: 24px;
		font-size: 18px;
		color: #ffffff;
		display: flex;
		font-weight: bold;
		align-items: center;
		justify-content: center;
		margin-top: 24px;
	}

	.linner {
		width: 90px;
		height: 1px;
		background: #CCCCCC;
	}

	.wx {
		width: 50px;
		margin: 0 auto;
	}

	.count {
		color: #999999 !important;
	}

	.ewm {
		width: 241px;
		height: 241px;
	}

	.s-icon {
		width: 48px;
		margin-bottom: 12px;
	}
  img{
  flex-shrink: 0;
}
</style>