<template>
	<div class="pos p-top">
		<div style="padding:27px 0;border-top: 1px solid #eee;">
			<div class="txt_center f16">{{title}}</div>
			<div class="flex flex_center" style="margin-top:56px;">
				<div class="main_bg cf p-btn pointer" style="margin-right: 40px;" @click="downFormSure(1)">是</div>
				<div class="bgccc cf p-btn pointer" @click="downFormSure(0)">否</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			title:{
				default:'是否删除？'
			}
		},
		methods:{
			downFormSure(t){
				this.$emit('handlePop',t)
			}
		}
	}
</script>

<style scoped>
	.p-top-title {
		position: absolute;
		top: -12px;
		left: 24px;
	}

	.p-btn {
		width: 100px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		font-size: 16px;
		border-radius: 2px;
	}

	.p-name {
		width: 50px;
	}

	.p-inp {
		border: 1px solid #eee;
		height: 36px;
		padding: 0 10px;
	}
</style>