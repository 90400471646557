<template>
	<div>
		<TOPNAV handleIndex="4"></TOPNAV>
		<div class="page_container">
			<div class="pc_container">
				<div class="pt24 flex_items txt_center f26 pt32 pb32">
					卓越产品体验快捷入口
				</div>
				<div class="flex flex_warp">
					<div v-for="(item,index) in list" :key="index" class="bgfff flex items pointer flex_between" 
					@click="gotoPage(item[2])">
						<div>
							<div class="bold c2 f24 mb16">{{item[0]}}</div>
							<div class="c8 f16">{{item[1]}}</div>
						</div>
						<img :src="item[3]" class="icon">
					</div>
				</div>
			</div>
		</div>
		

	</div>
</template>
<script>
	import TOPNAV from '@/components/topNav.vue'
	import {goLoginPage} from '@/utils/tool'
	export default ({
		components: {
			TOPNAV
		},
		data() {
			return {
				list: [],
				total: 20,
				pageSize: 10,
				currentPage: 1,
				pop: false,
				txt: '',
				delId: '',
				token: ''
			}
		},
		watch: {
			delId(newValue, oldValue) {
				if (!newValue) this.delId = ''
			}
		},
		created() {
			if(this.$storage.getItem('token')){
				this.getData()
			}
		},
		methods: {
			gotoPage(t) {
				this.token = this.$storage.getItem('token')
				if(!this.token){
					goLoginPage()
					return
				}
				t = t.replace(/{token}/g, this.token);
				window.open(t, '_blank');
			},
			del(id) {
				this.delId = id;
				this.pop = true;
			},
			downFormSure() {},
			handleClick() {

			},
			getData() {
				this.api.sites().then(res => {
					if(res.code == 0){
            this.list = res.data;
          }
				})
			},
			pageSizeC(e) {
				this.pageSize = e;
				this.currentPage = 1
				this.getData()
			},
			currentChange(e) {
				this.currentPage = e
				this.getData();
			},
		},
	})
</script>
<style src="@/assets/css/pc.css" scoped></style>
<style scoped>
	.page_container {
		background: #F7FAFC;
	}

	.p-top-title {
		position: absolute;
		top: -12px;
		left: 24px;
	}

	.p-btn {
		width: 100px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		font-size: 16px;
		border-radius: 2px;
	}

	.p-name {
		width: 50px;
	}

	.p-inp {
		border: 1px solid #eee;
		height: 36px;
		padding: 0 10px;
	}
	.items{
		width: 384px;
		height: 138px;
		margin-right: 24px;
		margin-bottom: 24px;
		border-radius: 5px;
		box-sizing: border-box;
		padding: 33px;
	}
	.items:nth-child(3n){
		margin-right: 0;
	}
	.icon{
		width: 72px;
		height: 72px;
		object-fit: cover;
		display: block;
	}
</style>