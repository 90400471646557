import { render, staticRenderFns } from "./customList.vue?vue&type=template&id=75d298eb&scoped=true&"
import script from "./customList.vue?vue&type=script&lang=js&"
export * from "./customList.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/pc.css?vue&type=style&index=0&id=75d298eb&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75d298eb",
  null
  
)

export default component.exports