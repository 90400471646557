<template>
	<div class="top-nav flex flex_between bgfff">
		<div class="pc_container flex_items flex f15">
			<img src="@/assets/pc/icon.png" class="img" alt="icon" style="margin-right: 4px;">
			<span class="c9 ">当前位置：
				<span v-for="(item,index) in tab1" class="pointer" @click="handleClick(item)" :key="index">{{item.name}} /
				</span></span>
			<span class="font_color" style="margin-left:5px"> {{tab2}}</span>
		</div>
	</div>
</template>

<script>
	export default {
		name: '',
		data() {
			return {
				userinfo: JSON.parse(localStorage.getItem("user")),
			};
		},
		props: {
			tab1: {
				default: []
			},
			tab2: {
				default: ''
			},
		},
		methods: {
			handleClick(item) {
				if (!item.path) return
				// if(item.path == -1){
				// 	this.$router.go(-1)
				// 	return
				// }
				let path = this.$route.path
				if(item.path == path) return
				let obj = {
					path:item.path
				}
				// console.log(item)
        obj.query = {}
				if(item.query){
					obj.query=item.query
				}
        // if(this.$route.query.proid) obj.query.proid = this.$route.query.proid
				this.$router.push(obj)
			},

		},
	};
</script>
<style src="@/assets/css/pc.css" scoped></style>
<style scoped>
	.top-nav {
		width: 100%;
		/* height: 53px; */
		padding:32px 0;
	}

	.pc_container {
		height: 100%;
	}

	.img {
		width: 18px;
		display: block;
		height: auto;
	}

	.pr64 {
		padding-right: 64px;
	}
</style>
