import axios from "axios";
import router from "../router/index"
import {
  // getQueryString,
  // SHEQUURL,
  // PUBLICURL,
  getOpenType, vRole,goLoginPage
} from '../utils/tool'
import {
  Toast
} from 'vant';
import {
  Loading,Message
} from 'element-ui';
let loading;

import { getItem } from './storage'
/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 * {
    const OK = 0;
    const Error = -1;//其他错误
    const FormError = -2;//表单验证失败
    const LoginError = -3;//登录失败，账号或密码错误
    const NotFoundError = -4;//记录未找到
    const DeniedError = -5;//没有权限
    const NeedLoginError = -6;//没有权限
    const TokenError = -9;//token不存在或已失效，需重新登录
	}
 */
export function post(url, params = {}, obj = null, urlParams = '') {
  const api_token = params.token ? params.token : getItem("token");
  params.v = new Date().getTime();
  if (urlParams) {
    url = url + '/' + urlParams
  }
  if (obj === null || (obj && obj.loading)) {
    loading = Loading.service({
      lock: true,
      background: 'rgba(0, 0, 0, 0.2)',
      text: obj && obj.loadingName ? obj.loadingName : '',
    })
  }
  return new Promise((resolve, reject) => {
    axios.post(url, params, {
        headers: {
          Authorization: 'Bearer ' + api_token
        }
      })
      .then(res => {
        if (obj === null || (obj && obj.loading)) {
          loading.close();
        }
        if (res.data.code == 0) {
          if(res.data.message == 'need_login'){
            reject(res)
          }else{
            resolve(res.data);
          }
        } else if (res.data.code == -9) {
          goLoginPage()
          // resolve(res.data);
          resolve(res.data);
        } else if (res.data.code == -2) {
					showErrorMsg(res.data)
          reject(res)
        }else{
          Message({
            message: res.data.message,
            type: 'error',
          });
          reject(res)
        }
      })
      .catch(err => {
        if (obj === null || (obj && obj.loading)) {
          loading.close();
        }
        Message({
          message: '网络异常',
          type: 'error',
        });
        reject(err.data)
      })
  });
}
export function get(url, params = {}, obj = null, urlParams = '') {
  let headers = {
    'Content-Type': 'application/json'
  }
	const api_token = params.token ? params.token : getItem("token");
  if(getOpenType() != 'mob' && !api_token && params.islogin !== false){
		let user = getItem('user') ? getItem('user').role : vRole;
    router.replace(user == vRole ? 'login' : 'custom_login')
    return
  }
  if(params.hasOwnProperty('islogin')){
    delete params.islogin
  }
  if (api_token) {
    headers.Authorization = 'Bearer ' + api_token;
  }
  if (obj === null || (obj && obj.loading)) {
    loading = Loading.service({
      lock: true,
      background: 'rgba(0, 0, 0, 0.2)',
      text: obj && obj.loadingName ? obj.loadingName : '',
    })
  }
  if (urlParams) {
    url = url + '/' + urlParams
  }
  return new Promise((resolve, reject) => {
    axios.get(url, {
        headers: headers,
        params: params
      })
      .then(res => {
        if (obj === null || (obj && obj.loading)) {
          loading.close();
        }
        if (res.data.code == 0) {
          resolve(res.data);
        } else if (res.data.code == -9) {
          
          goLoginPage()
          // reject(res.data);
          resolve(res.data);
        } else  if (res.data.code == -2) {
          showErrorMsg(res.data)
          reject(res)
        }else{
          Message({
            message: res.data.message,
            type: 'error',
          });
          reject(res)
        }
      })
      .catch(err => {
        console.log(JSON.parse(JSON.stringify(err)));
        if (obj === null || (obj && obj.loading)) {
          loading.close();
        }
        Message({
          message: '网络异常',
          type: 'error',
        });
        reject(err)
      })
  })
}
function showErrorMsg(d){
	let msg = d.errors;
	let msgStr = ''
	if(msg && typeof(msg) == 'object'){
		if(Array.isArray(msg)){
			msgStr = msg.map(r => {retrun (r + '</br>')}).join()
		}else{
			for(let i in msg){
				let str = ''
				if(Array.isArray(msg[i])){
					msg[i].map(r => {
						str += (r + '</br>')
					})
				}else{
					str = msg[i] + '</br>'
				}
				msgStr += str
			}
		}
	}else{
		msgStr = msg
	}
	Message({
		dangerouslyUseHTMLString:true,
	  message: msgStr,
	  type: 'error',
	});
	
}