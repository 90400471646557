<template>
	<div class="page_container">
		<TOPNAV handleIndex="3"></TOPNAV>
		<SLIDER :tab1="tab1" :tab2="tab2"></SLIDER>
		<div class="pc_container">
			<div class="flex">
				<div class="left">
					<div class="list pointer" @click="handleClick(item,index)" :class="inx == item.id ? 'handle main_color bold f16' : 'font_color f14'"
			      v-for="(item,index) in list" :key="index">{{item.name}}</div>
				</div>
				<div class="right flex1 bgfff">
					<template v-if="inx == 4">
						<div class="flex flex_between flex_items mb20">
						  <div class="page_title">个人信息</div>
						</div>
						<div class="list flex flex_items">
						  <div class="list_name c9 f15">账号：</div>
						  <div>{{user ? user.username : ''}}</div>
						</div>
						<div class="list flex flex_items ">
						  <div class="list_name c9 f15">姓名：</div>
						  <div>{{user ? user.realname : ''}}</div>
						</div>
						<div class="list flex flex_items">
						  <div class="list_name c9 f15">手机号：</div>
						  <div>{{user ? user.mobile : ''}}</div>
						</div>
						<div class="list flex flex_items ">
						  <div class="list_name c9 f15">邮箱：</div>
						  <div>{{user ? user.email : ''}}</div>
						</div>
						
					</template>
					<password v-if="inx == 1"></password>
					<phone v-if="inx == 2"></phone>
					<!-- <Emile v-if="inx == 3"></Emile> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import TOPNAV from '@/components/topNav.vue'
	import SLIDER from '@/components/slider.vue'
	import {	path	} from '@/utils/data.js'
	import password from '@/components/userPage/password'
	import phone from '@/components/userPage/phone'
	import Emile from '@/components/userPage/Emile'
	// import comment from '@/components/userPage/comment'
	export default {
		name: '',
		data() {
			return {
				list: [{
					id: 4,
					name: '个人信息'
				},{
					id: 1,
					name: '修改密码'
				}, {
					id: 2,
					name: `绑定/更换手机号`
				}, 
				// {
				// 	id: 3,
				// 	name: '绑定/更换邮箱'
				// },
				],
				inx:4,
				tab1: [{
					name: '个人中心',
					path: path()
				}],
				tab2: '',
				user:{}
			};
		},
		created() {
			this.getData()
			this.inx = 4;
      this.tab2 = this.list[0].name;
			this.user = this.$storage.getItem('user')
		},
    // beforeRouteEnter(to, from, next){
    //   next(vm => {
    //     if(from.path == '/pcsendPost'){
    //       vm.inx = 2
    //     }else if(from.path == '/pcpostDetail' || from.path == '/projectDetail'){
    //       vm.inx = from.query.t ? from.query.t : 1
    //     }
    //   })
    // },
		methods: {
			getData(){
				// this.api.memberInfo().then((res) => {
				// 	localStorage.setItem('user', JSON.stringify(res.data))
				// 	this.userinfo = res.data;
				// 	this.post_num = res.data.post_num
				// });
			},
			changemsg(){
				this.flag ++;
			},
			handleClick(e,index) {
        this.inx = e.id;
				this.tab2 = this.list[index].name
      },
		},
		components: {
			TOPNAV,SLIDER,password,phone,Emile
		}
	};
</script>
<style src="@/assets/css/pc.css" scoped></style>
<style scoped>
	.left{
		width:163px;
		background-color: #fff;
		min-height:880px;
		margin-right: 16px;
		border-right:1px solid #eee;
	}
	
	.list {
		width: 100%;
		height: 50px;
	}
  .right{
    padding-left: 64px;
    box-sizing: border-box;
  }
</style>