import Vue from 'vue'
import App from './App.vue'
import router from './router'
import * as api from './utils/api'
import {
	Icon
} from 'vant';
import {
	Lazyload,
	Image as VanImage,
	Switch,
	ImagePreview,
	Toast
} from 'vant';

import * as storage from './utils/storage';
import store from './store'
import 'vant/lib/index.less';
import $ from "jquery";
import { Button, Select ,Table, TableColumn,Pagination,Radio,RadioGroup,Dialog,Checkbox,Message,
  CheckboxGroup,} from 'element-ui';
import './assets/css/resetEl.css'
import 'element-ui/lib/theme-chalk/index.css';
// this.socket = new WebSocket("wss://" + location.host + ":9200");
// const socket = new WebSocket("ws://keyiche.anagelink.cn:9200");


Vue.prototype.api = api;
Vue.prototype.$storage = storage;
Vue.prototype.$JQ = $;
Vue.prototype.imagePreview = ImagePreview;
Vue.prototype.Toast = Toast;


Vue.use(Icon);
Vue.use(Lazyload);
Vue.use(VanImage);
Vue.use(Switch);
//---------------el------------
Vue.use(Button);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Dialog);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.prototype.$message = Message;
Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
