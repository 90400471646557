import { getOpenType ,vRole} from "@/utils/tool";
import Vue from 'vue'
import VueRouter from 'vue-router'
import INDEX from '../views/tabbar/index.vue'
import HOME from '../views/pc/home.vue'
import MINE from '../views/tabbar/mine.vue'
import LOGIN from '../views/login/login.vue'



import E from '../views/home/empty.vue'; //登录空白页过度页
import loginSuccess from '../views/login/pc/loginSuccess.vue'




//---------------------------------------------------------------------------------------
import CHOOSE from '../views/mobile/choose.vue'
import pclogin from '../views/login/pc/login.vue'
import pcUserLogin from '../views/login/pc/userLogin.vue'
import account from '../views/pc/account.vue'
import addAccount from '../views/pc/addAccount.vue'
import manageAccount from '../views/pc/manageAccount.vue'
import customList from "../views/pc/customList.vue"
import addCustom from "../views/pc/addCustom.vue"
import siteList from "../views/pc/siteList.vue"
import addSite from "../views/pc/addSite.vue"
import userPage from "../views/pc/userPage.vue"


Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'index',
    component: INDEX,

    meta: {
      title: "首页",
      content: "首页"
    },
    redirect: "/home",
    // children: [{
    //     path: '/home',
    //     name: 'home',
    //     component: HOME,
    //     meta: {
    //       title: "首页",
    //       content: "首页"
    //     }
    //   },
    //   {
    //     path: '/msg',
    //     name: 'msg',
    //     component: MSG,
    //     meta: {
    //       title: "消息",
    //       content: "消息",
    //       mustLogin: true
    //     }
    //   },
    //   {
    //     path: '/mine',
    //     name: 'mine',
    //     component: MINE,
    //     meta: {
    //       title: "我的",
    //       content: "我的"
    //     }
    //   },
    // ]
  },
  //手机卓越登录
  {
    path: "/mblogin",
    name: "login",
    component: LOGIN,
    meta: {
      title: "登录",
      content: "登录",
      pcPath:'/login',
      mobPath:'/mblogin'
    },
  },
  {
    path: "/choose",
    name: "choose",
    component: CHOOSE,
    meta: {
      title: "卓越产品体验平台管理后台",
      content: "卓越产品体验平台管理后台"
    },
  },
  {
    path: "/loginSuccess",
    name: "loginSuccess",
    component: loginSuccess,
    meta: {
      title: "卓越产品体验平台管理后台",
      content: "卓越产品体验平台管理后台"
    },
  },
  //PC端卓越登录
  {
    path: "/login",
    name: "pclogin",
    component: pclogin,
    meta: {
      title: "卓越",
      content: "卓越",
      pcPath:'/login',
      mobPath:'/mblogin'
    },
  },
  //pc客户登录
  {
    path: "/custom_login",
    name: "pcUserLogin",
    component: pcUserLogin,
    meta: {
      title: "卓越",
      content: "卓越",
      pcPath:'/custom_login',
      mobPath:'/mblogin'
    },
  },
  {
    path: "/home",
    name: "home",
    component: HOME,
    meta: {
      title: "卓越",
      content: "卓越",
			mustLogin: true
    },
  },
  {
    path: "/account",
    name: "account",
    component: account,
    meta: {
      title: "账号管理",
      content: "账号管理",
      mustLogin: true
    },
  },
	{
	  path: "/addAccount",
	  name: "addAccount",
	  component: addAccount,
	  meta: {
	    title: "",
	    content: "",
	    mustLogin: true
	  },
	},
	{
	  path: "/manageAccount",
	  name: "manageAccount",
	  component: manageAccount,
	  meta: {
	    title: "账号授权",
	    content: "账号授权",
	    mustLogin: true
	  },
	},
  {
	  path: "/customList",
	  name: "customList",
	  component: customList,
	  meta: {
	    title: "客户管理",
	    content: "客户管理",
	    mustLogin: true
	  },
	},
	{
	  path: "/addCustom",
	  name: "addCustom",
	  component: addCustom,
	  meta: {
	    title: "",
	    content: "",
	    mustLogin: true
	  },
	},
	{
	  path: "/siteList",
	  name: "siteList",
	  component: siteList,
	  meta: {
	    title: "站点管理",
	    content: "站点管理",
	    mustLogin: true
	  },
	},
  {
	  path: "/addSite",
	  name: "addSite",
	  component: addSite,
	  meta: {
	    title: "",
	    content: "",
	    mustLogin: true
	  },
	},
  {
	  path: "/userPage",
	  name: "userPage",
	  component: userPage,
	  meta: {
	    title: "个人中心",
	    content: "个人中心",
	    mustLogin: true
	  },
	},
  {
    path: "/emptyPage",
    name: "emptyPage",
    component: E,
    meta: {
      title: "",
      content: ""
    },
  },
]

const router = new VueRouter({
  // mode: 'history',
  routes: routes,

  scrollBehavior(to, from, saveTop) {
    if (saveTop) {
      return saveTop;
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})


router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }

  const TOKEN = localStorage.getItem("token");
  // const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : false;
  if (getOpenType() == "mob") { //手机端
    if (to.meta && to.meta.mustLogin && !TOKEN) {
      next({ path: '/mblogin' })
    } else {
      if(to.meta.mobPath && to.meta.pcPath && to.path == to.meta.pcPath){
        next({ path: to.meta.mobPath })
      }else{
        next()
      }
    }
  }else{
    if (to.meta && to.meta.mustLogin && !TOKEN) { //pc端没有登录
      let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).role : vRole;
      let url = user == vRole ? '/login' : '/custom_login'
      next({ path: url })
    } else {
      if(to.meta.mobPath && to.meta.pcPath && to.path == to.meta.mobPath){
        next({ path: to.meta.pcPath })
      }else{
        next()
      }
    }
  }
  // if (to.meta && to.meta.mustLogin && !TOKEN) {
 
  // } else {
  //   next()
  // }

})



export default router