<template>
  <div class="content">
    <div class="content-page">
      <div class="logo-top pos">
        <img
          class="logo"
          src="@/assets/pc/logo.png"
          alt=""
        />
        <div class="logo-font">用户登录</div>
      </div>
      <div class="content-box">
        <div class="flex ser">
          <div :class="handle == 1 ? 'main_color bold' : 'c9'" @click="handle = 1">手机号登陆</div>
          <div :class="handle == 2 ? 'main_color bold' : 'c9'" @click="handle = 2">账号登录</div>
        </div>
        <div v-if="handle == 1">
          <div class="input-box">
            <div class="input-box-left"><img src="@/assets/pc/i1.png"/></div>
            <div class="input-box-right">
              <input
                type="text"
                v-model="mobile"
                placeholder="请输入手机号码"
                maxlength="11"
              />
            </div>
          </div>
          <div class="input-box">
            <div class="input-box-left"><img src="@/assets/pc/i2.png"/></div>
            <div class="input-box-right">
              <input type="text" placeholder="请输入验证码" v-model="code" />
            </div>
            <div class="get-code main_color" @click="get_code" v-show="count == 60">
              获取验证码
            </div>
            <div class="get-code count" v-show="count < 60">{{ count }}秒重试</div>
          </div>
        </div>
         <div v-if="handle == 2">
          <div class="input-box">
            <div class="input-box-left"><img src="@/assets/pc/i5.png"/></div>
            <div class="input-box-right">
              <input
                type="text"
                v-model="username"
                placeholder="请输入账号/邮箱"
              />
            </div>
          </div>
          <div class="input-box">
            <div class="input-box-left"><img src="@/assets/pc/i4.png"/></div>
            <div class="input-box-right">
              <input type="password" placeholder="请输入密码" v-model="password" />
            </div>
          </div>
        </div>
        <div class="login-btn" @click="login">登录</div>
      </div>




      <!--    <div class="division">
        <span>or</span>
      </div> -->

      <!-- <div class="login-mode">
        <div class="flex flex_items w100 flex_center" @click="wxsignin">
          <img src="../../assets/member/wx.png" />
          微信登录
        </div>
      </div> -->
      <div
        class="mt50 txt_center f24 pb30 flex flex_center flex_items"
        @click="check = !check"
      >
        <Checkbox
          v-model="check"
          icon-size="15"
          checked-color="#00D8CC"
          class="mr10"
        ></Checkbox
        >我已阅读并同意<span
          class="main_color"
          @click.stop="$router.push('/msgtxt')"
          >《用户协议》</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { Checkbox } from "vant";
import { HERF,getQueryString} from "@/utils/tool";
export default {
  name: "",
  props: {},
  components: {
    Checkbox,
  },
  data() {
    return {
      mobile: "",
      code: "",
      count: 60,
      HERF: HERF,
      check: false,
      handle:1,
    };
  },
  watch: {},
  mounted() {},
  created() {},
  methods: {
    login() {
      //1-手机号登陆 2-账号登录 3-微信登录
      if(this.handle == 1){
        this.phoneLogin()
      }else{
        this.usernameLogin()
      }
    },
    phoneLogin(){
      if (!this.mobile) {
        return this.$message.error("手机号不能为空");
      }
      if (!this.code) {
        return this.$message.error("验证码不能为空");
      }
      this.api.login({
        mobile: this.mobile,
        code: this.code,
        type:'mobile',
      })
      .then((res) => {
        let obj = {
        	name:'token',
        	value:res.data.token,
        }
        this.$storage.setItem(obj)
        this.$storage.setItem({
        	name:'user',
        	value:res.data,
        })
        this.$router.replace('/choose');
      },(res) => {
        console.log(res);
        // if(res.data.code == )
      });
    },
    usernameLogin(){
      if (!this.username) {
        return this.$message.error("账号不能为空");
      }
      if (!this.password) {
        return this.$message.error("密码不能为空");
      }
      this.api.login({
        username: this.username,
        password: this.password,
        type:'username',
      })
      .then((res) => {
    		let obj = {
    			name:'token',
    			value:res.data.token,
    		}
    		if(!this.remb){
    			obj.expires= 24 * 60 * 60 * 1000
    			obj.startTime = new Date().getTime()
    		}
    		this.$storage.setItem(obj)
    		this.$storage.setItem({
    			name:'user',
    			value:res.data,
    		})
    		this.$router.replace('/choose');
      },(res) => {
        console.log(res);
        // if(res.data.code == )
      });
    },
    // 获取验证码
    get_code() {
      if (!this.mobile) {
        return this.Toast("手机号不能为空");
      }
      this.api
        .sms({
          mobile: this.mobile,
          type: "login",
        })
        .then(() => {
          this.Toast("发送成功");
          this.count -= 1;
          let a = setInterval(() => {
            this.count -= 1;
            if (this.count == 1) {
              this.count = 60;
              clearInterval(a);
            }
          }, 1000);
        });
    },
    // wxsignin(type = 0) {
    //   if (!this.check) {
    //     this.Toast("请勾选用户协议");
    //     return;
    //   }
    //   localStorage.setItem('wxlogin',1)
    //   const appid = APPID;
    //   const url = location.origin + location.pathname + location.hash;
    //   const redirect_uri = encodeURIComponent(url);
    //   // if (type == 1) {
        
    //   location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=${1}#wechat_redirect`;
    //   // }
    // },
    oninputmobile() {
      if (!/^1[34578]\d{9}$/.test(this.mobile)) {
        this.Toast("手机号码有误，请重填");
      }
    },
    getWxUserInfo(code) {
      let userCode = sessionStorage.getItem("userCode");
      this.code = code;
      this.api
        .login({
          code,
        })
        .then((res) => {
          if(res.code == 200){
            localStorage.setItem("token", res.data.api_token);
            localStorage.setItem("openid", res.data.openid);
            this.$router.replace("home");
          }
        });
    },
		gotoLien(){
			this.api.user().then((res) => { //获取用户手机号
			  let obj = {};
			  if (JSON.parse(localStorage.getItem("user"))) {
			    obj = {
			      ...JSON.parse(localStorage.getItem("user")),
			      ...res.data,
			    };
			  } else {
			    obj = res.data;
			  }
			  localStorage.setItem("user", JSON.stringify(obj));
				localStorage.removeItem("fromKey");
			});
		},
  },
};
</script>
<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100vh;
  background: #F7FAFC;
  overflow: hidden;

  //   background-position: center;
}
.content-page{
  background: url('../../assets/pc/login.png') top left;
  background-size: contain;
  background-repeat: no-repeat;
}
.content-box{
  background: #fff;
  border-radius: .3rem;
  margin: 0 .32rem;
  height: 100vh;
  box-sizing: border-box;
  padding: 0 .55rem;
}
.logo-top{
  padding-top: 2rem;
  padding-left: .65rem;
  margin-bottom: 1.15rem;
}
.logo {
  width: 1.8rem;
  height: .64rem;
  overflow: hidden;
  // border-radius: 50%;
  // background-color: #4fd9d5;
  margin-bottom: 0.17rem;
}
.ser{
  margin-bottom: .56rem;
}
.ser>div{
  padding-top: .32rem;
  width: 50%;
  box-sizing: border-box;
  text-align: center;
  position: relative;
  height: .8rem;
}
.ser>div.main_color::after{
  position: absolute;
  width: .3rem;
  height: .06rem;
  border-radius: .06rem;
  content: " ";
  bottom: -.06rem;
  left:50%;
  transform: translateX(-50%);
  background: #4EAE92;
}
.logo-font{
  font-size: .4rem;
  font-weight: bold;
  color: #333;
}

.input-box {
  margin-bottom: 0.42rem;
  height: .88rem;
  background: #ECF6F4;
  border-radius: 0.1rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding-right: 0.06rem;

  .input-box-left {
    padding:0 .24rem;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: .44rem;
    }
  }

  .input-box-right {
    flex: 1;
    width: 100%;
    height: 100%;

    input {
      width: 100%;
      height: 100%;
      border: none;
      background-color: transparent;
      font-size: 0.3rem;
    }
  }

  .get-code {
    width: 1.8rem;
    height: 0.88rem;
    border-radius: 0.44rem;
    font-size: 0.28rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.login-btn {
  height: .88rem;
  background: #4EAE92;
  border-radius: 0.1rem;
  font-size: 0.32rem;
  color: #ffffff;
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  margin-top: 0.88rem;
}

.ps {
  margin: 0 0.24rem;
  font-size: 0.24rem;
  color: #cccccc;
  text-align: center;
  margin-top: 0.72rem;

  span {
    color: #4fd9d5;
  }
}

.division {
  margin: 0.3rem 0.24rem;
  height: 1px;
  background-color: #e6e6e6;
  position: relative;

  span {
    font-size: 0.36rem;
    font-weight: 400;
    color: #e6e6e6;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 0 0.4rem;
  }
}

.login-mode {
  display: flex;
  justify-content: center;
  margin-top: 0.6rem;
  background-color: #00d8cc;
  width: 6rem;
  height: 0.98rem;
  margin: 0 auto;
  border-radius: 0.16rem;
  color: #fff;
  font-size: 0.32rem;
  line-height: 0.98rem;

  img {
    width: 0.54rem;
    margin-right: 0.16rem;
  }

  p {
    font-size: 0.3rem;
    color: #999999;
    text-align: center;
  }
}

.count {
  background: #999999 !important;
}

.font {
  font-size: 0.36rem;
  color: #1f3349;
  font-weight: 500;
  text-align: center;
  margin-bottom: 2.41rem;
}
.mt50 {
  margin-top: 0.5rem;
}
</style>
