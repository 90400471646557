import axios from "axios";
import router from "../router/index"
import {
    Toast
} from 'vant';
import {
    jsSdk
} from './api'
import { getItem } from './storage'
// 线上
// export const HERF = 'https://login.vmr.cn/'
// export const SOHERF = '';
export const APPID = 'wxe8153b43313f3931'; //正式服APPid


//测试服
// export const HERF = 'http://login.anage.cn/'
export const HERF = 'https://login.vmr.cn/'
// export const APPID = 'wx4becac545b32fa7c';
// export const APPID = 'wx412703392677e279';


export const vRole = 'vantage';

export const ewmStyleBase64 = 'data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsKIGRpc3BsYXk6IG5vbmU7Cn0KLmltcG93ZXJCb3ggLnN0YXR1cy5zdGF0dXNfYnJvd3NlciB7CiBkaXNwbGF5OiBub25lOwp9Ci5pbXBvd2VyQm94IC5xcmNvZGUgewogYm9yZGVyOiBub25lOwogbWFyZ2luOiAwOwogd2lkdGg6IDIyMHB4OwogaGVpZ2h0OiAyMjBweDsKfQouaW1wb3dlckJveCAuc3RhdHVzewogZGlzcGxheTogbm9uZQp9'


//每页默认多少条
export const pageSize = 20

// import {
//   resolve
// } from "core-js/fn/promise";
const imageCoversion = require("image-conversion");
//上传图片
export function uploadimg(file) {
    const api_token = getItem("token");
    const formdata = new FormData();
    // console.log(file.file.type, 'type');
    const isJpgOrPng = file.file.type === 'image/jpeg' || file.file.type === 'image/png';
    if (!isJpgOrPng) {
        Toast('上传图片只能是JPG或PNG 格式!');
        return false;
    }
    formdata.append('api_token', api_token)
    return new Promise((resolve, reject) => {
        imageCoversion
            .compressAccurately(file.file, 300)
            .then((res1) => {
                console.log(file.file, '-----------file.file');
                console.log(res1, '-----------res1');
                formdata.append('file', res1)

                let config = {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    }
                    // return new Promise((resolve, reject) => {
                axios.post("/api/uploadImg", formdata, config).then(res => {
                        console.log(res.data);
                        if (res.data.code == 0) {
                            resolve(res.data.data)
                        } else {
                            Toast(res.data.message)
                            resolve(false)
                        }
                    }).catch(err => {
                        Toast(err)
                        reject(err)
                    })
                    // })
            })
            .catch((res) => {
                console.log(res);
                Toast("上传失败，请稍后重试");
            });
    })
}


export function formatRichText(html) { //控制小程序中图片大小
    if (!html) return '';
    return html.replace(/\<img/gi, '<img style="width:100%;height:auto" ');
}

export function getTimer(end_time) {
    let end = new Date(end_time).getTime();
    let now = new Date().getTime();
    let endFont = parseInt((Number(end) - now) / 1000);
    if (endFont > 86400) {
        return parseInt((end - now) / (1000 * 60 * 60 * 24)) + '天';
    } else if (endFont <= 86400 && endFont > 3600) {
        return parseInt((end - now) / (3600 * 1000)) + '小时';
    } else if (endFont < 3600 && endFont > 60) {
        return parseInt((end - now) / (60 * 1000)) + '分钟'
    } else {
        return null;
    }
}

export function getCode() {
    let usercode = getQueryString('usercode');
    // console.log(u1);
    if (usercode) {
        sessionStorage.setItem('userCode', usercode);
        // window.location.href = HERF + '/h5/#/sq'
    }
}
//获取浏览器上的参数
export function getQueryString(variable) {
    let herf = window.location.href;
    let num = herf.indexOf("?") + 1;
    let query = herf.substring(num);
    let vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        if (pair[0] == variable) {
            return pair[1];
        }
    }
    return false;
}

// 分享配置
import wx from "jweixin-module";
export function shareJsSdk(shareUrl, shareTitle = '', shareDesc = '', detailId = '', shareImg = '', usercode = '') {
    //detailId  --分享人的openid
    const u = navigator.userAgent;
    let that = this;
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    let url = "";
    if (isiOS) {
        url = window.location.href.split("#")[0];
        // url = window.location.href;
    } else {
        url = window.location.href;
    }

    setTimeout(() => {
        jsSdk({
            url
        }).then((res) => {
            wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: res.data.appId, // 必填，公众号的唯一标识
                timestamp: res.data.timestamp, // 必填，生成签名的时间戳
                nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
                signature: res.data.signature, // 必填，签名
                jsApiList: ["updateAppMessageShareData",
                    "updateTimelineShareData"
                ], // 必填，需要使用的JS接口列表
            });
            let user = getItem("user");
            if (user) {
                user = JSON.parse(user);
            } else {
                user = "";
            }

            let u = "#" + window.location.href.split("#")[1];
            console.log(detailId, 'detailId')
            if (detailId) {
                u = HERF + "h5/#/" + shareUrl + "?memberCode=" + detailId + "&share=1";
            } else {
                u = HERF + "h5/#/" + shareUrl + "?share=1";
            }

            if (usercode) {
                u = u + '&usercode=' + usercode
            }

            // console.log(u, "-----------shareurl----------");
            let title = shareTitle ? shareTitle : ""; //标题
            let desc = shareDesc ? shareDesc : ""; //描述文字
            // console.log(des, "---------");
            wx.ready(function() {
                // var myurl = url;
                // var myurl = 'http://keyiche.anagelink.cn/h5';
                var imgUrl = shareImg ? shareImg : HERF + "img/logo.png";
                //分享给朋友
                wx.updateAppMessageShareData({
                    title: title, // 分享标题
                    desc: desc, // 分享描述
                    link: u, // 分享链接
                    imgUrl: imgUrl, // 分享图标
                    success: function() {
                        // 用户确认分享后执行的回调函数
                    },
                    cancel: function() {
                        // 用户取消分享后执行的回调函数
                    },
                });
                wx.updateTimelineShareData({
                    title: title, // 分享标题
                    desc: desc, // 分享描述
                    link: u, // 分享链接
                    imgUrl: imgUrl, // 分享图标
                    success: function() {
                        // 用户确认分享后执行的回调函数
                    },
                    cancel: function() {
                        // 用户取消分享后执行的回调函数
                    },
                });

                wx.error((res) => {
                    console.log(res);
                });
                // TODO
            });
        });
    }, 500);
}
export function questionFont(t) {
    //0-填写中 1-配额已满 2-甄别终止 3-甄别完成 4-正常完成
    if (t == 4 || t == 3) {
        return '已完成，感谢您的参与'
    } else if (t == 2) {
        return '很遗憾，您不符合本次任务的要求'
    } else if (t == 1) {
        return '很遗憾，本次所需人数已满'
    } else if (t == 99) {
        return '很遗憾，本次任务已过期'
    } else if (t == 98) {
        return '本次任务未开始'
    }
}

export function questionType(t) {
  t = Number(t)
  let qt = ''
  switch (t) {
      case 1:
          qt = '投票';
          break;
      case 2:
          qt = '单选';
          break;
      case 3:
          qt = '多选';
          break;
      case 4:
          qt = '问答';
          break;
      case 5:
          qt = '标注';
          break;
      default:
          qt = ''
  }
  return qt
}

//抽奖状态
export function prizeStatus(t) {
  t = Number(t)
  let qt = ''
  switch (t) {
      case 1:
          qt = '可以抽奖';
          break;
      case 0:
          qt = '无抽奖活动或活动已结束';
          break;
      case -1:
          qt = '奖品已抽完';
          break;
      case -8:
          qt = '不满足抽奖条件';
          break;
      case -9:
          qt = '已抽过奖';
          break;
      default:
          qt = ''
  }
  return qt
}
//获取打开方式
export function getOpenType() {
  // //微信端（微信浏览器）打开进手机端  其他的都进pc端
  // var ua = navigator.userAgent.toLowerCase();
  // var isWeixin = ua.indexOf('micromessenger') != -1;
  // // console.log(isWeixin,'isWeixin---------------------');
  // if (isWeixin) {
  //   return 'mob';
  // }else{
  //   return 'pc';
  // }
  var ua = navigator.userAgent,
    isWindowsPhone = /(?:Windows Phone)/.test(ua),
    isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
    isAndroid = /(?:Android)/.test(ua),
    isFireFox = /(?:Firefox)/.test(ua),
    isChrome = /(?:Chrome|CriOS)/.test(ua),
    isTablet =
    /(?:iPad|PlayBook)/.test(ua) ||
    (isAndroid && !/(?:Mobile)/.test(ua)) ||
    (isFireFox && /(?:Tablet)/.test(ua)),
    isPhone = /(?:iPhone)/.test(ua) && !isTablet,
    isPc = !isPhone && !isAndroid && !isSymbian;
  let os = {
    isTablet: isTablet,
    isPhone: isPhone,
    isAndroid: isAndroid,
    isPc: isPc
  }
  if (os.isTablet) {
    // 平板
    return 'pad'
  } else if (os.isAndroid || os.isPhone) {
    // 手机
    return 'mob'
  } else if (os.isPc) {
    // pc
    if (document.body.clientWidth < 768) {
      //小屏
      return 'mob'
    } else {
      //大屏
      return 'pc'
    }
  }
}
//校验手机号
export function checkPhone (phone) {
  if (!(/^1[3456789]\d{9}$/.test(phone))) {
    return false;
  } else {
    return true;
  }
}
//未登录跳转跳转
export function goLoginPage(){
	let user = getItem('user') ? getItem('user').role : vRole;
  localStorage.removeItem('token');
	let url = ''
	if(getOpenType() == 'mob'){
		url = '/mblogin'
	}else{
		url = user == vRole ? '/login' : '/custom_login'
	}



	
	router.replace({
	  path: url,
	  query: {
	    redirect: router.currentRoute.fullPath
	  }
	})
}