import { post,get } from "./https"
// import vRole  from './tool'
import { getItem } from './storage'

let vRole = 'vantage';


function getRole(){
  let role = getItem('user') ? getItem('user').role : vRole;
  return role == vRole ? 1 : 0;
}
// console.log(getTimer,'--------------');
//登录
export const login = p => post("/api/login", p);
export const sms = p => post("/api/sms", p);

//个人站点
export const sites = p => get("/api/sites", p);
//获取个人信息
export const info = p => get("/api/info", p);
//微信获取配置
export const wechatConfig = p => get("/api/wechat/config", p);
//微信回调
export const wechatCode = p => post("/api/wechat/code", p);

//账号列表
export const accountList = (p) => {
  let url = getRole() == 1 ? '/api/admin/account/list' : '/api/custom/account/list'
  return get(url, p)
}
//创建账号
export const accountSave = (p) => {
  let url = getRole() == 1 ? '/api/admin/account/save' : '/api/custom/account/save'
  return post(url, p)
}
//获取账号详情
export const accountView = (p, o, upr)  => {
  let url = getRole() == 1 ? '/api/admin/account/view' : '/api/custom/account/view'
  return get(url,p, o, upr)
}
//删除账号
export const accountDelete = (p, o, upr)  => {
  let url = getRole() == 1 ? '/api/admin/account/delete' : '/api/custom/account/delete'
  return post(url,p, o, upr)
}
//获取可授权站点列表
export const customSites = (p, o, upr)  => {
  let url = getRole() == 1 ? '/api/admin/custom/sites' : '/api/custom/account/sites'
  return get(url,p, o, upr)
}
//授权站点
export const customPermission = (p, o, upr)  => {
  let url = getRole() == 1 ? '/api/admin/custom/permission' : '/api/custom/account/permission'
  return post(url,p, o, upr)
}
//客户公司列表
export const companyList = p => get("/api/admin/company/list", p);
export const companySave = p => post("/api/admin/company/save", p);
export const companyView = p => get("/api/admin/company/view", p);
export const companyDelete = p => post("/api/admin/company/delete", p);

//添加客户账号
export const customSave = p => post("/api/admin/custom/save", p);
export const customView = p => get("/api/admin/custom/view", p);
export const customDelete = p => post("/api/admin/custom/delete", p);
export const password = p => post("/api/password", p);
export const mobile = p => post("/api/mobile", p);




//获取用户信息
export const user = p => post("/api/user/info", p);
//userInfoByCode
export const userInfoByCode = p => post("/api/user/userInfoByCode", p);

//jsjdk
export const jsSdk = p => post("/api/other/JsSDK", p);
