<template>
	<div class="page_container">
		<TOPNAV :handleIndex="handleIndex"></TOPNAV>
		<SLIDER :tab1="tab1" :tab2="tab2"></SLIDER>
		<div class="pc_container">
			<div class="flex flex_between flex_items" style="margin-bottom: 32px;">
				<div class="page_title">站点信息</div>
			</div>
			<div>
				<div class="list flex flex_start mb24">
					<div class="list_name c9 f15" style="margin-bottom: 10px;">站点</div>
					<el-checkbox-group v-model="listVal" text-color="#4eae92" class="flex flex1 flex_warp">
						<div v-for="(item,index) in list" :key="index" class="mr32" style="margin-bottom: 10px;">
							<el-checkbox :label="item.value">{{item.label}}</el-checkbox>
						</div>
					</el-checkbox-group>
				</div>
				<div class="list flex flex_items" style="margin-top: 56px;">
					<div class="list_name c9 f15"></div>
					<div class="page_btn main_bg" @click="save">保存</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import TOPNAV from '@/components/topNav.vue'
	import SLIDER from '@/components/slider.vue'
	import {
		path
	} from '@/utils/data.js'
	export default ({
		components: {
			TOPNAV,
			SLIDER
		},
		data() {
			return {
				nickname: '',
				tab1: [{
					name: '账号管理',
					path: path(0),
				}],
				cid:'',//公司id
				tab2: '账号授权',
				total: 20,
				pageSize: 10,
				currentPage: 1,
				id: '',
				sex: '1',
				list: [],
				listVal:[],
				handleIndex:'',
			}
		},
		created() {
			this.id = this.$route.query.id;
			this.cid = this.$route.query.cid;
			this.handleIndex = this.cid ? 1 : 0;
			this.tab1[0].query={
				cid:this.cid
			}
			this.customView()
		},
		methods: {
			save() {
				const formData = new FormData();
				this.listVal.map((r,i) => {
					formData.append('sites['+i+']', r)
				})
				formData.append('id', this.id)
				this.api.customPermission(formData).then(res => {
					this.$message.success('授权成功')
					this.$router.go(-1)
				})
			},
			customView(){
				this.api.customView({id:this.id}).then(res => {
					this.listVal = res.data.sites
					this.getData()
				})
			},
			getData() {
				this.api.customSites().then(res => {
					let arr = []
					for(let i in res.data){
						let r = res.data[i]
						arr.push({
							label:r[0],
							intor:r[1],
							url:r[2],
							img:r[3],
							value:i
						})
					}
					this.list = arr
				})
			},
			pageSizeC(e) {
				this.pageSize = e;
				this.currentPage = 1
				this.getData()
			},
			currentChange(e) {
				this.currentPage = e
				this.getData();
			},
		},
	})
</script>
<style src="@/assets/css/pc.css" scoped></style>
<style scoped>
	.page_btn {
		width: 95px;
		height: 48px;
		font-size: 18px;
	}
</style>