<template>
  <!-- 头部 -->
  <div class="top-nav">
    <div class="pc_container flex flex_between flex_items">
      <div class="left flex flex_items h100">
        <img src="@/assets/pc/logo.png" class="logo show">
				<div class="bold c3 f20 ml26">卓越产品体验平台管理后台</div>
      </div>
      <div class="flex right h100">
				<div class="flex f18 flex_items h100 mr24">
					<div class="h100 right-items pos pointer" :class="handleIndex == 4 ? 'main_color bold' : 'c9'" @click="handleClick('/home')">首页</div>
					<div class="h100 right-items pos pointer" :class="handleIndex == 0 ? 'main_color bold' : 'c9'" @click="handleClick('/account')" v-if="is_admin == 1">账号管理</div>
					<div class="h100 right-items pos pointer" :class="handleIndex == 1 ? 'main_color bold' : 'c9'" @click="handleClick('/customList')" v-if="is_admin == 1 && role == 'vantage'">客户管理</div>
					<!-- <div class="h100 right-items pos pointer" :class="handleIndex == 2 ? 'main_color bold' : 'c9'" @click="handleClick('/siteList')">站点管理</div> -->
					<div class="h100 right-items pos pointer" :class="handleIndex == 3 ? 'main_color bold' : 'c9'" @click="handleClick('/userPage')">个人中心</div>
				</div>
				<div class="flex flex_items c3 f16 flex_items">
					<div style="line-height:1">欢迎您：</div>
          <div class="pos pointer more pt10">
            {{user.username}} <img src="@/assets/pc/up.png" class="up" />
            <div class="more-box-f" id="more-box-f">
            <img src="@/assets/pc/sj.png" class="show">
            <div class="w100 bgfff">
              <div class="flex_items flex pointer font_color f16" @click="pop = true">
                <img src="@/assets/pc/out.png" style="width: 18px;height: 18px" class="show mr10">
                退出登录
              </div>
            </div>
          </div>
          </div>
				</div>
			</div>
    </div>
    <el-dialog title="退出登录" :visible.sync="pop" width="330px" top="35vh" :append-to-body="true" :close-on-click-modal="false">
			<delPop @handlePop="handlePop" title="是否确定退出登录？"></delPop>
		</el-dialog>
  </div>
</template>
<script>
import delPop from '@/components/delPop.vue'
import { goLoginPage} from '@/utils/tool'
export default ({
  data() {
    return{
      pop:false,
      user:null,
      role:'',//vantage 卓越
      is_admin:0,//是否是管理员
    }
  },
	props:{
		handleIndex:{
			default:0
		}
	},
  components: {
    delPop
  },
  created () {
    this.user = this.$storage.getItem('user');
    this.is_admin = this.user ? this.user.is_admin : 0;
    this.role = this.user ? this.user.role : 0;
    console.log(this.user);
  },
  methods: {
    handleClick(url){
			let path = this.$route.path
			if (url == '' || path == url) {
				return
			}
			this.$router.push({path:url,query:{}})
		},
    handlePop(t) {
      if(t == 0){
        this.pop = false
      }else{
        localStorage.removeItem('token')
        sessionStorage.removeItem('unionid')
        sessionStorage.removeItem('openid')
        goLoginPage()
      }
    },
  }
})
</script>
<style src="@/assets/css/pc.css" scoped></style>
<style scoped>
.top-nav{
  height: 76px;
  border-bottom: 4px solid #F7FAFC;
  box-sizing: border-box;
  background: #fff;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	width: 100%;
}
.logo{
	width: 125px;
	height: 45px;
}
.right-items{
	line-height: 76px;
	width: 136px;
	text-align: center;
}
.right-items:first-child::before{
	background: #fff;
}
.right-items::before{
	width: 2px;
	height: 16px;
	background: #ccc;
	content: " ";
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
}
.right-items.main_color::after{
	width: 72px;
	content: " ";
	height: 2px;
	position: absolute;
	bottom: 4px;
	left: 50%;
	transform: translateX(-50%);
}
.up{
	margin-left: 8px;
	width: 17px;
	height: 11px;
}
.more-box-f {
  position: absolute;
  bottom: -98px;
  width: 120px;
  height: 100px;
  box-sizing: border-box;
  font-size: 15px;
  display: none;
  left: 50%;
  transform: translateX(-50%);
  z-index: 666;
  padding-top: 15px;
}

.more-box-f>img {
  width: 30px;
	height: 15px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.more:hover .more-box-f{
  display: block;
}
.more-box-f>div {
  border-radius: 10px;
  padding: 17px 13px;
  box-sizing: border-box;
  box-shadow: 0 1px 10px 0 rgba(0,0,0, 0.1);
}
</style>