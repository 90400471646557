<template>
	<div>
		<TOPNAV handleIndex="2"></TOPNAV>
		<div class="pc_container">
			<div class="flex flex_between pt24 flex_items" style="margin-bottom: 14px;">
				<div class="page_title">站点列表</div>
				<div class="page_btn main_bg" @click="$router.push('/addSite')">新建站点</div>
			</div>
			<div>
				<el-table :data="list" style="width: 100%"
					:header-cell-style="{background:'#F3F3F3',color:'#1F3349',fontSize:'15px',fontWeight:'normal'}"
					:cell-style="{fontSize:'15px',color:'#666666'}">
					<el-table-column prop="id" label="序号"  align="left">
					</el-table-column>
					<el-table-column align="left" prop="name" label="站点名称">
					</el-table-column>
					<el-table-column align="left" prop="address" label="创建时间">
						<template slot-scope="scope">
							<div class="flex" :class="scope.row.nature == 1 ? 'main_color_red' : 'c6'">
								{{scope.row.nature == 1 ? '停用' : '启用'}}
							</div>
						</template>
					</el-table-column>
					<el-table-column fixed="right" align="center" label="操作" width="210">
						<template slot-scope="scope">
							<div class="flex flex_items flex_center">
								<div @click="$router.push({path:'/addSite',query:{id:scope.row.id}})" class="main_bg  s_btn">
									编辑
								</div>
								<div class="main_bg_red ml10 s_btn" @click="del(scope.row)">删除</div>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="flex flex_end" style="margin-top: 32px;">
				<el-pagination background layout="prev, pager, next,sizes,jumper" :total="total" @current-change="currentChange"
					:current-page="currentPage" :page-size="pageSize" @size-change="pageSizeC">
				</el-pagination>
			</div>
		</div>
		<el-dialog title="删除" :visible.sync="pop" width="330px" top="35vh" :close-on-click-modal="false">
			<delPop @handlePop="handlePop" title="是否确定删除该账号？"></delPop>
		</el-dialog>
	</div>
</template>
<script>
	import TOPNAV from '@/components/topNav.vue'
	import delPop from '@/components/delPop.vue'
	export default ({
		components: {
			TOPNAV,delPop
		},
		data() {
			return {
				list: [{
					name: 123,
					manageName: 123,
					nature: 0,
					address: 123,
					id: 2
				}],
				total: 20,
				pageSize: 10,
				currentPage: 1,
				pop:false,
				txt:'',
				delId:'',
			}
		},
		watch: {
			delId(newValue, oldValue) {
				if (!newValue) this.delId = ''
			}
		},
		created(){
		},
		methods: {
			handlePop(t) {
				if(t == 0){
					this.pop = false
				}else{
					
				}
			},
			del(id) {
				this.delId = id;
				this.pop = true;
			},
			downFormSure(){},
			handleClick() {

			},
			getData() {

			},
			pageSizeC(e) {
				this.pageSize = e;
				this.currentPage = 1
				this.getData()
			},
			currentChange(e) {
				this.currentPage = e
				this.getData();
			},
		},
	})
</script>
<style src="@/assets/css/pc.css" scoped></style>
<style scoped>
  .p-top-title{
    position: absolute;
    top: -12px;
    left: 24px;
  }
  .p-btn{
		width: 100px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		font-size: 16px;
		border-radius: 2px;
	}
  .p-name{
    width: 50px;
  }
  .p-inp{
    border: 1px solid #eee;
    height: 36px;
    padding: 0 10px;
  }
</style>