import { render, staticRenderFns } from "./home.vue?vue&type=template&id=3e8c7f70&scoped=true&"
import script from "./home.vue?vue&type=script&lang=js&"
export * from "./home.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/pc.css?vue&type=style&index=0&id=3e8c7f70&scoped=true&lang=css&"
import style1 from "./home.vue?vue&type=style&index=1&id=3e8c7f70&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e8c7f70",
  null
  
)

export default component.exports