<template>
	<div class="page_container">
		<TOPNAV handleIndex="1"></TOPNAV>
		<div class="pc_container">
			<div class="flex flex_between pt24 flex_items" style="margin-bottom: 14px;">
				<div class="page_title">客户公司列表 https://login.vmr.cn/h5/#/custom_login</div>
				<div class="page_btn main_bg" @click="$router.push('/addCustom')">新建公司</div>
			</div>
			<div>
				<el-table :data="list" style="width: 100%"
					:header-cell-style="{background:'#F3F3F3',color:'#1F3349',fontSize:'15px',fontWeight:'normal'}"
					:cell-style="{fontSize:'15px',color:'#666666'}">
					<el-table-column prop="id" label="序号" width="150" align="center">
					</el-table-column>
					<el-table-column align="left" prop="name" label="公司名称">
					</el-table-column>
					<el-table-column align="left" prop="detail" label="公司简介">
						<template slot-scope="scope">
							<div class="clamp1" style="max-width: 200px;">{{scope.row.detail}}</div>
						</template>
					</el-table-column>
					<el-table-column align="left" prop="updated_at" label="创建时间">
					</el-table-column>
					<el-table-column fixed="right" align="center" label="操作" width="210">
						<template slot-scope="scope">
							<div class="flex flex_items flex_center">
								<div @click="$router.push({path:'/addCustom',query:{id:scope.row.id}})" class="main_bg  s_btn">
									编辑
								</div>
								<div class="main_bg_red ml10 s_btn" @click="del(scope.row.id)">删除</div>
								<div @click="$router.push({path:'/account',query:{cid:scope.row.id}})" class="ml10 main_bg_ba s_btn">
									进入
								</div>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="flex flex_end" style="margin-top: 32px;">
				<el-pagination background layout="prev, pager, next,sizes,jumper" :total="total" @current-change="currentChange"
					:current-page="currentPage" :page-size="pageSize" @size-change="pageSizeC">
				</el-pagination>
			</div>
		</div>
		<el-dialog title="删除" :visible.sync="pop" width="330px" top="35vh" :close-on-click-modal="false">
			<delPop @handlePop="handlePop" title="是否确定删除该客户？"></delPop>
		</el-dialog>
	</div>
</template>
<script>
	import TOPNAV from '@/components/topNav.vue'
	import delPop from '@/components/delPop.vue'
	import {pageSize} from '@/utils/tool'
	export default ({
		components: {
			TOPNAV,delPop
		},
		data() {
			return {
				list: [],
				total: 20,
				pageSize: pageSize,
				currentPage: 1,
				pop:false,
				txt:'',
				delId:'',
			}
		},
		watch: {
			delId(newValue, oldValue) {
				if (!newValue) this.delId = ''
			}
		},
		created(){
			this.getData()
		},
		methods: {
			handlePop(t) {
				if(t == 0){
					this.pop = false
				}else{
					this.api.companyDelete({id:this.delId}).then(res => {
						this.$message.success('删除成功')
						this.getData()
						this.pop = false
					},() => {
						this.pop = false
					})
				}
			},
			del(id) {
				this.delId = id;
				this.pop = true;
			},
			downFormSure(){},
			handleClick() {

			},
			getData() {
				this.api.companyList({page:this.currentPage,per_page:this.pageSize}).then(res => {
					this.list = res.data.data;
					this.total = res.data.total;
				})
			},
			pageSizeC(e) {
				this.pageSize = e;
				this.currentPage = 1
				this.getData()
			},
			currentChange(e) {
				this.currentPage = e
				this.getData();
			},
		},
	})
</script>
<style src="@/assets/css/pc.css" scoped></style>
<style scoped>

</style>