<template>
  <!-- @click="clickMore" -->
  <div id="app" v-cloak>
    <keep-alive>
      <!-- 需要缓存的视图组件 -->
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <!-- 不需要缓存的视图组件 -->
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>
<script>
export default {
  name: "App",
  created() {},
  methods: {
    clickMore(event) {
      let dom = this.$JQ("#more-box-f");
      dom.hide();
      event.stopPropagation();
    },
  },
  mounted() {
    // 关闭loading
    // document.getElementById('app').style.display = 'block';
    // document.getElementById('appLoading').style.display = 'none';
  },
};
</script>

<style lang="scss">
body,
html {
  min-height: 100vh;
}
@import url("../src/assets/css/reset.css");

html {
  font-size: calc(100vw / 7.5);
}

@media screen and (min-width: 576px) {
  html {
    font-size: calc(576px / 7.5);
  }
}

@media screen and (max-width: 576px) {
  #app {
    max-width: 576px;
    margin: 0 auto;
  }
}

[v-cloak] {
  display: none;
}

.van-tabbar-item__icon .van-info {
  background: #f9647d;
}
.el-loading-spinner .path {
  stroke: #4eae92 !important;
}
</style>
