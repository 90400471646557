//页面路径
export function path(type) {
  switch (type) {
    case -1: //返回
      return -1
    case 0: //账号管理
      return '/account'
    case 1: //客户管理
      return '/customList'
    case 2: //站点管理
      return '/siteList'
    default:
      return ''
  }
}